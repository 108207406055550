import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FaBoxOpen } from 'react-icons/fa';
import SpinLoader from '../components/SpinLoader.js';
import { toast } from 'react-toastify';
import { handleLogin } from '../Common/TokenLogin.js';
import useScrollToTop from '../components/useScrollToTop.js';


const validationSchema = Yup.object({
    AWBNumber: Yup.string()
        .required('AWB Number is required')
        .matches(/^[a-zA-Z0-9]+$/, 'AWB Number is invalid'),
});

const TrackForm = ({ setTrackingInfo, setModalOpen }) => {
    useScrollToTop();

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            AWBNumber: '',
        },
        validationSchema,
        onSubmit: async values => {
            const { AWBNumber } = values;
            setIsLoading(true);
            try {
                const authToken = await handleLogin();
                const response = await fetch(`https://shipment.xpressbees.com/api/shipments2/track/${AWBNumber}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}`,
                    }
                });
                const data = await response.json();
                if (data?.status) {
                    if (data?.data?.status === "cancelled") {
                        setTrackingInfo(null);
                        setError('Courier not found.');
                    } else {
                        setTrackingInfo(data?.data);
                        setModalOpen(true);
                    }
                } else {
                    setError('Courier not found.');
                }
            } catch (error) {
                toast.error(`Error tracking shipment: ${error.message}`);
                setError('Error tracking shipment. Please try again.');
            } finally {
                setIsLoading(false);
            }
        },
    });

    return (
        <>

            <div className="lg:w-1/2 md:rounded-l-lg rounded-none md:py-8 md:px-9 p-6 bg-white ring-1 h-full ring-slate-900/5 shadow-md md:shadow-md">
                <form onSubmit={formik.handleSubmit}>
                    <div className='space-y-5'>
                        <div className="flex space-x-3 justify-between text-xl font-bold mb-7">
                            <div className='flex'>
                                <FaBoxOpen className="h-7 w-7" />
                                <h3 className="text-slate-800 ms-2">Can't find your delivery?</h3>
                            </div>
                            <p className='hidden lg:hidden xl:block md:block text-gray-400 font-normal text-sm'>Anytime, Anywhere</p>
                        </div>
                        <div>
                            <p className="text-slate-900 font-medium mb-2">Find your delivery using your Tracking AWB Number</p>
                        </div>
                        <p className='text-md font-semibold'>AWB Number <span className="text-red-500">*</span></p>
                    </div>

                    <div className="sm:col-span-4 mt-2">
                        <div className="flex">
                            <input
                                type="text"
                                name="AWBNumber"
                                id="AWBNumber"
                                autoComplete="AWBNumber"
                                className="flex-1 border-0 focus:outline-none focus:ring-slate-500 focus:border-slate-500 rounded-md shadow-sm ring-1 ring-slate-400 bg-transparent py-1.5 px-4 text-gray-900 placeholder:text-gray-400 sm:text-md sm:leading-6"
                                placeholder="Enter Your AWB Number"
                                onChange={(e) => {
                                    const cleanedValue = e.target.value.replace(/\s+/g, '');
                                    formik.setFieldValue('AWBNumber', cleanedValue);
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.AWBNumber}
                            />
                        </div>
                        {formik.touched.AWBNumber && formik.errors.AWBNumber ? (
                            <div className="text-red-500 text-sm mt-2">{formik.errors.AWBNumber}</div>
                        ) : null}
                    </div>
                    {error && <div className="text-red-500 mt-1">{error}</div>}

                    <button
                        type="submit"
                        className="mt-5 w-full text-center rounded-md bg-slate-900 py-2 text-md font-semibold text-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        disabled={!formik.isValid || isLoading}
                    >
                        {isLoading ? (
                            <div className='flex justify-center items-center'>
                                <span className='mr-2'>Loading...</span>
                                <SpinLoader />
                            </div>
                        ) : (
                            'Track Now'
                        )}
                    </button>

                    <p className="text-center mt-4 text-sm font-semibold lg:mt-10 xl:mt-5">Ship and track from the comfort of your home</p>
                </form>

            </div>
            <div className="w-full lg:w-1/2 bg-white p-6 md:rounded-r-lg shadow-md mt-6 lg:mt-0">
                <p className="font-semibold mb-6">What’s your Courier status?</p>
                <div className="mb-4">
                    <h3 className="font-semibold text-slate-800 text-sm">Courier Picked</h3>
                    <p className="text-gray-500 text-sm">Your Courier has been picked up.</p>
                </div>
                <div className="mb-4">
                    <h3 className="font-semibold text-slate-800 text-sm">Courier In Transit</h3>
                    <p className="text-gray-500 text-sm">Your Courier is on it’s way to your customer’s address</p>
                </div>
                <div className="mb-4">
                    <h3 className="font-semibold text-slate-800 text-sm">Out For Delivery</h3>
                    <p className="text-gray-500 text-sm">The courier executive is on its way to deliver the order at your Delivery Address</p>
                </div>
                <div className="mb-4">
                    <h3 className="font-semibold text-slate-800 text-sm">Delivered</h3>
                    <p className="text-gray-500 text-sm">Your Courier has been delivered</p>
                </div>
            </div>



        </>
    );
};

export default TrackForm;
