import './App.css';
import { BrowserRouter as Router, Route, Switch, Routes, useLocation, Navigate } from 'react-router-dom';
import Home from './Home/Home.js';
import AllOrders from './OrderPage/AllOrders.js';
import Notification from './Notification/Notification.js';
import AccordionHelp from './Help/AccordionHelp.js';
import RetrictedItem from './RetrictedItem/RetrictedItem.js';
import PackGuidelines from './RetrictedItem/PackGuidelines.js';
import Signup from './user verification/Signup.js';
import TrackCourier from './TrackCourier/TrackCourier.js';
// import Orders from './OrderPage/Orders.js';
import SingleTrack from './OrderPage/SingleTrack.js';
import CalculatorCourier from './CalculatorCourier/CalculatorCourier.js';
import DocumentShipping from './DocumentShiping/DocumentShipping.js';
import AdminHome from './SuperAdminPanel/AdminHome.js';
import AdminTodayOrder from './SuperAdminPanel/AdminTodayOrder.js';
import AdminTrack from './SuperAdminPanel/AdminTrack.js';
import AdminTicket from './SuperAdminPanel/AdminTicket.js';
import AdminTotalCustomer from './SuperAdminPanel/AdminTotalCustomer.js';
import AdminAd from './SuperAdminPanel/AdminAd.js';
import AdminPayment from './SuperAdminPanel/AdminPayment.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter } from "react-router-dom";
import Carousel from './SuperAdminPanel/Carousel.js';
import SuccessPayment from './DocumentShiping/SuccessPayment.js';
import withSession from './Session/withSession.js';
import TermsAndConditions from './Footer/TermsAndConditions.js';
import PrivacyPolicy from './Footer/PrivacyPolicy.js';
import FraudDisclaimer from './Footer/FraudDisclaimer.js';
import Notfoundpage from './404page/Notfoundpage.js';
import AdminComision from './SuperAdminPanel/AdminComision.js';
import Modal from './Common/paymentReturn.js';
import AdminRefund from './SuperAdminPanel/AdminRefund.js';
import AdminTopbar from './SuperAdminPanel/AdminTopbar.js';
import AdminEdit from './SuperAdminPanel/AdminEdit.js';
import AdminPolicy from './SuperAdminPanel/AdminPolicy.js';
import AdminIncome from './SuperAdminPanel/AdminIncome.js';
import SameDay from './DocumentShiping/SameDay.js';
import ForeignAir from './DocumentShiping/ForeignAir.js';
import Cookies from './Footer/Cookies.js';
import MobileUpdate from './Common/MobileUpdate.js';
import AnotherHelp from './Help/AnotherHelp.js';
import ShippingLabel from './Home/ShippingLabel.js';
import Label from './Home/Label.js';
import NetworkStatus from './networkError/NetworkStatus.js';
import ShipLabel from './OrderPage/ShipLabel.js';
import { useEffect } from 'react';
import BulkCourier from './DocumentShiping/BulkCourier.js';
function App({ session, refetch }, props) {


  const login = localStorage.getItem('role');
  const AdminRoute = ({ children, session }) => {
    // console.log('✌️session --->', session);
    // console.log('✌️children --->', children);
    const location = useLocation();
    // console.log('✌️location --->++++', location);
    const me = session?.me;
    // console.log('✌️me --->', me);
    const role = me?.userType;
    // console.log('✌️role --->', role);
    if(role){
      if (!me || !isAdmin(role)) {
        return <Navigate to="/signup" state={{ from: location }} replace />;
      }else{
        return children;
      }
    }else{

      return children;
    }
  };

  const isAdmin = (role) => {
    if ((role === 'Admin'))
      return true
    else
      return false
  }

  const PublicRoute = ({ children, session }) => {
    const location = useLocation();
    let role = session && session?.me;
    if (role) {
      if (role && isAdmin(role?.userType)) {
        return <Navigate to="/adminhome" state={{ from: location }} replace />;
      } else {
        return children;
      }
    } else {
      return children
    }
  };
  const CustomerRoute = ({ children, session }) => {
    const location = useLocation();
    let role = session && session?.me;
    if (role || login) {
      if (role && isAdmin(role?.userType) || login === 'Admin') {
        return <Navigate to="/adminhome" state={{ from: location }} replace />;
      } else {
        return children;
      }
    } else {
      return <Navigate to="/signup" state={{ from: location }} replace />;
    }
  };
  return (
    <>
      <BrowserRouter>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          limit={2}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />

        <NetworkStatus />
        <Routes>
          {/* //public routes */}
          <Route exact path="/" element={<PublicRoute session={session}><Home /></PublicRoute>} />
          <Route exact path="/signup" element={<PublicRoute session={session}><Signup refetch={refetch} /></PublicRoute>} />
          <Route exact path="/packguidlines" element={<PublicRoute session={session}><PackGuidelines refetch={refetch} /></PublicRoute>} />
          <Route exact path="/trackcourier" element={<PublicRoute session={session}><TrackCourier refetch={refetch} /></PublicRoute>} />
          <Route exact path="/calculatorcourier" element={<PublicRoute session={session}><CalculatorCourier refetch={refetch} /></PublicRoute>} />
          <Route exact path="/termsAndConditions" element={<PublicRoute session={session}><TermsAndConditions refetch={refetch} /></PublicRoute>} />
          <Route exact path="/privacyPolicy" element={<PublicRoute session={session}><PrivacyPolicy refetch={refetch} /></PublicRoute>} />
          <Route exact path="/cookiespolicy" element={<PublicRoute session={session}><Cookies refetch={refetch} /></PublicRoute>} />
          <Route exact path="/fraudDisclaimer" element={<PublicRoute session={session}><FraudDisclaimer refetch={refetch} /></PublicRoute>} />
          <Route exact path="/notfoundpage" element={<PublicRoute session={session}><Notfoundpage refetch={refetch} /></PublicRoute>} />
          <Route exact path="/retrictedItem" element={<PublicRoute session={session}>{<RetrictedItem />}</PublicRoute>} />
          <Route exact path="/accordionhelp" element={<PublicRoute session={session}>{<AccordionHelp />}</PublicRoute>} />
          <Route exact path="/notification" element={<PublicRoute session={session}>{<Notification />}</PublicRoute>} />
          <Route exact path="/sameday" element={<PublicRoute session={session}>{<SameDay />}</PublicRoute>} />
          <Route exact path="/foreignair" element={<PublicRoute session={session}>{<ForeignAir />}</PublicRoute>} />
          <Route exact path="/anotherhelp" element={<PublicRoute session={session}>{<AnotherHelp />}</PublicRoute>} />
          <Route exact path="/shippinglabel" element={<PublicRoute session={session}>{<ShippingLabel />}</PublicRoute>} />
          <Route exact path="/label" element={<PublicRoute session={session}>{<Label />}</PublicRoute>} />



          <Route exact path="/allOrders" element={<CustomerRoute session={session}>{<AllOrders />}</CustomerRoute>} />
          <Route exact path="/documentshipping" element={<CustomerRoute session={session}>{<DocumentShipping />}</CustomerRoute>} />
          <Route exact path="/bulkcourier" element={<CustomerRoute session={session}>{<BulkCourier />}</CustomerRoute>} />
          <Route exact path="/singletrack" element={<CustomerRoute session={session}>{<SingleTrack />}</CustomerRoute>} />
          <Route exact path="/SuccessPayment" element={<CustomerRoute session={session}>{<SuccessPayment />}</CustomerRoute>} />

          {/* <Route path="/order" element={<Orders />} /> */}

          <Route path="/adminhome" element={<AdminRoute session={session}><AdminHome /></AdminRoute>} />
          <Route path="/carousel" element={<AdminRoute session={session}><Carousel /></AdminRoute>} />
          <Route path="/admintodayorder" element={<AdminRoute session={session}><AdminTodayOrder /></AdminRoute>} />
          <Route path="/admintrack" element={<AdminRoute session={session}><AdminTrack /></AdminRoute>} />
          <Route path="/adminticket" element={<AdminRoute session={session}><AdminTicket /></AdminRoute>} />
          <Route path="/admintotalcustomer" element={<AdminRoute session={session}><AdminTotalCustomer /></AdminRoute>} />
          <Route path="/adminad" element={<AdminRoute session={session}><AdminAd /></AdminRoute>} />
          <Route path="/adminpayment" element={<AdminRoute session={session}><AdminPayment /></AdminRoute>} />
          <Route path="/AdminComision" element={<AdminRoute session={session}><AdminComision /></AdminRoute>} />
          <Route path="/AdminRefund" element={<AdminRoute session={session}><AdminRefund /></AdminRoute>} />
          <Route path="/AdminTopbar" element={<AdminRoute session={session}><AdminTopbar /></AdminRoute>} />
          <Route path="/AdminEdit" element={<AdminRoute session={session}><AdminEdit /></AdminRoute>} />
          <Route path="/AdminPolicy" element={<AdminRoute session={session}><AdminPolicy /></AdminRoute>} />
          <Route path="/AdminIncome" element={<AdminRoute session={session}><AdminIncome /></AdminRoute>} />



          <Route path="*" element={<Notfoundpage />} />
        </Routes>
        <Modal />
        <MobileUpdate />
        {/* <ShipLabel /> */}
      </BrowserRouter>

    </>
  );
}

export default withSession(App);
