import React, { useState } from 'react'
import Header from '../Header/Header.js'
import { FaCaretRight, FaRegEdit } from 'react-icons/fa'
import Select from 'react-select';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import Footer from '../Footer/Footer.js';
import AddressModal from './AddressModel.js';
import AddressDetailsModel from './AddressDetailsModel.js';
import DeliveryDetailsModel from './DeliveryDetailsModel.js';
import DeliveryModal from './DeliveryModal.js';
import PaymentTotalModel from './PaymentTotalModel.js';
import useScrollToTop from '../components/useScrollToTop.js';
import { GET_DELIVERY_ADDRESS } from '../graphql/Query/GetDeliveryOfCustomer.js';
import { useMutation, useQuery } from '@apollo/client';
import { GET_RECEIVER_DATA } from '../graphql/Query/GetReceiverOfCustomer.js';
import { BULK_ADD_ORDER } from '../graphql/Mutation/Ordre.js';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import SpinLoader from '../components/SpinLoader.js';
import { handleLogin } from '../Common/TokenLogin.js';
import { ORDER_COMMITION } from '../graphql/Mutation/OrderCommition.js';
import { RETURN_URL } from '../config.js';
import { cashfree } from '../utils/CashFreePayment.js';

const weightOptions = [
    { value: "0.5 Kg", label: "0.5 KG" },
    { value: "1 Kg", label: "1 Kg" },
    { value: "2 Kg", label: "2 Kg" },
    { value: "3 Kg", label: "3 Kg" },
    { value: "4 Kg", label: "4 Kg" },
    { value: "5 Kg", label: "5 Kg" },
    { value: "6 Kg", label: "6 Kg" },
    { value: "7 Kg", label: "7 Kg" },
    { value: "8 Kg", label: "8 Kg" },
    { value: "9 Kg", label: "9 Kg" },
    { value: "10 Kg", label: "10 Kg" },
    { value: "11 Kg", label: "11 Kg" },
    { value: "12 Kg", label: "12 Kg" },
    { value: "13 Kg", label: "13 Kg" },
    { value: "14 Kg", label: "14 Kg" },
    { value: "15 Kg", label: "15 Kg" },
    { value: "16 Kg", label: "16 Kg" },
    { value: "17 Kg", label: "17 Kg" },
    { value: "18 Kg", label: "18 Kg" },
    { value: "19 Kg", label: "19 Kg" },
    { value: "20 Kg", label: "20 Kg" },
    { value: "21 Kg", label: "21 Kg" },
    { value: "22 Kg", label: "22 Kg" },
    { value: "23 Kg", label: "23 Kg" },
    { value: "24 Kg", label: "24 Kg" },
    { value: "25 Kg", label: "25 Kg" },
    { value: "26 Kg", label: "26 Kg" },
    { value: "27 Kg", label: "27 Kg" },
    { value: "28 Kg", label: "28 Kg" },
    { value: "29 Kg", label: "29 Kg" },
    { value: "30 Kg", label: "30 Kg" },
];

const options = [
    { value: 'Books & Documents', label: 'Books & Documents' },
    { value: 'Clothes & Personal Items', label: 'Clothes & Personal Items' },
    { value: 'Consumables', label: 'Consumables' },
    { value: 'Electronics', label: 'Electronics' },
    { value: 'Household Items', label: 'Household Items' },
    { value: 'Sports Equipment', label: 'Sports Equipment' },
    { value: 'Furniture', label: 'Furniture' },
    { value: 'Jewelry & Accessories', label: 'Jewelry & Accessories' },
    { value: 'Beauty & Personal Care', label: 'Beauty & Personal Care' },
    { value: 'Toys & Games', label: 'Toys & Games' },
    { value: 'Office Supplies', label: 'Office Supplies' },
    { value: 'Groceries', label: 'Groceries' },
    { value: 'Health & Beauty', label: 'Health & Beauty' },
    { value: 'Automotive', label: 'Automotive' },
    { value: 'Musical Instruments', label: 'Musical Instruments' },
    { value: 'Gardening Tools', label: 'Gardening Tools' },
    { value: 'Kitchen Appliances', label: 'Kitchen Appliances' },
    { value: 'Pet Supplies', label: 'Pet Supplies' },
    { value: 'Art & Craft', label: 'Art & Craft' },
    { value: 'Baby Products', label: 'Baby Products' },
    { value: 'Tools & Hardware', label: 'Tools & Hardware' },
    { value: 'Travel Accessories', label: 'Travel Accessories' },
    { value: 'Industrial Supplies', label: 'Industrial Supplies' },
    { value: 'Bags & Luggage', label: 'Bags & Luggage' },
    { value: 'Footwear', label: 'Footwear' },
    { value: 'Home Decor', label: 'Home Decor' },
    { value: 'Other', label: 'Other' },

];


const BulkCourier = () => {
    useScrollToTop();
    // const [paymentSessionId, setSessionId] = useState();
    const [showDeliveryModal, setShowDeliveryModal] = useState(false);
    const [selectedOrderIndex, setSelectedOrderIndex] = useState(null);
    const [courierCharges, setCourierCharges] = useState([]);
    // console.log('✌️courierCharges --->', courierCharges);
    const [orders, setOrders] = useState([{
        weightInput: '',
        WeightType: '',
        courier_id: '',
        ProductPrice: '',
        orderAmount: '',
        weight: '',
        productCategory: { value: '', label: '' },
        selectedPickupAddress: null,
        selectedDeliveryAddress: null,
    }]);
    const { data: orderPercentageData } = useQuery(ORDER_COMMITION);
    // console.log("orders==", orders)
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState([]);
    // console.log('✌️orders --->', orders);
    const [priceCalculated, setPriceCalculated] = useState(Array(orders.length).fill(false));
    const [BlulkAddOrder] = useMutation(BULK_ADD_ORDER);
    const [selectedPickupAddresses, setSelectedPickupAddresses] = useState([]);
    const [selectedDeliveryAddresses, setSelectedDeliveryAddresses] = useState([]);
    const [weightTypes, setWeightTypes] = useState([]);

    const [addressForms, setAddressForms] = useState([{ id: 1 }]);
    const [courier_id, setcourier_id] = useState('');
    const [weightInput, setWeightInput] = useState('');
    const [WeightType, setWeightType] = useState('');
    const [deliveryshowModal, setdeliveryShowModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedPickupAddress, setSelectedPickupAddress] = useState('');
    const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState('');
    const [showModalAddress, setShowModalAddress] = useState(false);
    const [paymentShowModel, setPaymentShowModel] = useState(false);
    const [deliveryModal, setdeliveryModal] = useState(false);
    const id = localStorage.getItem('id');
    const [pickupAddress, setPickupAddress] = useState({
        warehouse_name: '',
        name: '',
        address1: '',
        city: '',
        stateId: {
            id: '',
            name: '',
        },
        zipCode: '',
        phone: ''
    });
    const validationSchema = Yup.object({
        weight: Yup.string().required('Weight is required'),
        ProductPrice: Yup.number()
            .required('Product Value is required')
            .positive('Product Value must be a positive number')
            .typeError('Product Value must be a number'),
        productCategory: Yup.object()
            .shape({
                value: Yup.string().required('Product Category is required'),
            })
            .required('Product Category is required'),
    });
    const [deliveryAddress, setDeliveryAddress] = useState({
        name: '',
        address1: '',
        city: '',
        stateId: {
            id: '',
            name: '',
        },
        zipCode: '',
        phone: ''
    });
    const addMoreAddress = () => {
        setAddressForms([...addressForms, { id: addressForms.length + 1, pickupAddress: {}, deliveryAddress: {} }]);
    };
    const { data: getDeliveryData, refetch } = useQuery(GET_DELIVERY_ADDRESS, {
        variables: {
            "customerId": id,
            "page": 1,
            "limit": 10,
            "sort": {
                "key": "createdAt",
                "type": -1
            },
        },
        fetchPolicy: 'cache-and-network',
    });
    const { data: getReceiverData, refetch: reciverRefetch } = useQuery(GET_RECEIVER_DATA, {
        variables: {
            "customerId": id,
            "page": 1,
            "limit": 10,
            "sort": {
                "key": "createdAt",
                "type": -1
            },
        },
        fetchPolicy: 'cache-and-network',
    });



    const handleOrderChange = (index, event) => {
        const newOrders = [...orders];
        newOrders[index][event.target.name] = event.target.value;
        setOrders(newOrders);
    };

    // const handleAddOrder = async () => {
    //     setOrders([...orders, { pickupAddress: {}, deliveryAddress: {}, package_weight: '', courierCategory: '', courierValue: '' }]);
    // };

    const handleAddOrder = async () => {
        const lastOrder = orders[orders.length - 1];

        // Check if the necessary fields are filled in the last order
        const requiredFields = [
            lastOrder?.selectedPickupAddress,
            lastOrder?.selectedDeliveryAddress,
            lastOrder?.weightInput,
            lastOrder?.WeightType,
            lastOrder?.productCategory?.value,
            lastOrder?.ProductPrice,
            lastOrder?.weight
        ];

        const isFormValid = requiredFields.every(field => field);

        if (!isFormValid) {
            toast.error("Please ensure all required fields are filled out before adding a new order.");
            return;
        }

        // Add a new order row if the current one is filled
        setOrders([...orders, {
            weightInput: '',
            WeightType: '',
            courier_id: '',
            ProductPrice: '',
            orderAmount: '',
            weight: '',
            productCategory: { value: '', label: '' },
            selectedPickupAddress: null,
            selectedDeliveryAddress: null,
        }]);
        setPriceCalculated((prevState) => [...prevState, false]);
    };

    const handleCalculateCourier = async (index) => {
        try {

            const newLoadingState = [...isLoadingData];
            newLoadingState[index] = true; // Set loading state for this index
            setIsLoadingData(newLoadingState);
            const authToken = await handleLogin();
            if (authToken) {
                const order = orders[index];
                // console.log('✌️order --->-----------', order);
                const { selectedPickupAddress, selectedDeliveryAddress, weightInput } = order;
                // console.log('✌️weightInput --->', weightInput);
                // console.log('✌️selectedPickupAddress, selectedDeliveryAddress, weightInput --->', selectedPickupAddress, selectedDeliveryAddress, weightInput);


                if (!selectedPickupAddress || !selectedDeliveryAddress || !weightInput) {
                    toast.error(`Missing fields for order no.${index + 1}`);
                    newLoadingState[index] = false; // Reset loading state
                    setIsLoadingData(newLoadingState);
                    return;
                }


                const response = await fetch('https://shipment.xpressbees.com/api/courier/serviceability', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}`,
                    },
                    body: JSON.stringify({
                        origin: selectedPickupAddress?.zipCode,
                        destination: selectedDeliveryAddress?.zipCode,
                        payment_type: 'prepaid',
                        weight: weightInput,
                    }),
                });

                const data = await response.json();
                // console.log('✌️data --->', data);

                if (response.ok && data?.status) {
                    let filteredData = [];
                    // Filtering based on weight
                    switch (true) {
                        case (weightInput === 500):
                            filteredData = data.data.filter(item => item.name === 'Surface Xpressbees 0.5 K.G');
                            break;
                        case (weightInput === 1000):
                            filteredData = data.data.filter(item => item.name === 'Xpressbees 1 K.G');
                            break;
                        case (weightInput === 2000 || weightInput === 3000 || weightInput === 4000):
                            filteredData = data.data.filter(item => item.name === 'Xpressbees 2 K.G');
                            break;
                        case (weightInput === 5000 || weightInput === 6000 || weightInput === 7000 || weightInput === 8000 || weightInput === 9000):
                            filteredData = data.data.filter(item => item.name === 'Xpressbees 5 K.G');
                            break;
                        case (weightInput >= 10000):
                            filteredData = data.data.filter(item => item.name === 'Xpressbees 10 K.G');
                            break;
                        default:
                            break;
                    }

                    let selectedData;
                    if (data?.data?.length > 1 && filteredData.length > 0) {
                        selectedData = filteredData[0];
                    } else if (!data?.data?.some(item => item.name === 'Xpressbees 10 K.G')) {
                        throw new Error(`Service for destination pincode not availble`);
                    } else {
                        throw new Error(`No.${index + 1} Courier services are not available for the selected route`);
                    }
                    // Calculate the final charges
                    const orderPercentage = orderPercentageData?.getCommitionAmount?.order_percentage || 0;
                    const percentageMultiplier = 1 + (orderPercentage / 100);

                    const updatedCharges = {
                        ...selectedData,
                        freight_charges: Math.round(selectedData?.freight_charges * percentageMultiplier),
                        total_charges: Math.round(selectedData?.total_charges * percentageMultiplier),
                    };

                    setCourierCharges([updatedCharges]);
                    setPriceCalculated(prev => {
                        const newArray = [...prev];
                        newArray[index] = true; // Mark this index as having calculated price
                        return newArray;
                    });
                    const updatedOrders = [...orders];
                    updatedOrders[index] = {
                        ...updatedOrders[index],
                        orderAmount: updatedCharges?.total_charges, // Update the price in the order
                    };

                    setOrders(updatedOrders);
                    setIsLoadingData(false);
                } else {
                    // toast.error(data?.data);
                    // Check for specific error messages and replace them
                    let errorMessage = data?.data;
                    if (errorMessage === 'Origin Pincode Not Servicable.') {
                        errorMessage = 'Pickup Pincode Not Servicable.';
                    } else if (errorMessage === 'Destination Pincode Not Servicable.') {
                        errorMessage = 'Delivery Pincode Not Servicable.';
                    }

                    // Display the error message using toast
                    toast.error(errorMessage);
                }
            }

        } catch (error) {
            toast.error(error?.message || 'An error occurred while calculating the price.');
        } finally {
            const newLoadingState = [...isLoadingData];
            newLoadingState[index] = false; // Reset loading state
            setIsLoadingData(newLoadingState);
        }
    };

    // const handleSubmit = async (event) => {
    //     event.preventDefault();
    //     try {
    //         const { data } = await addOrders({ variables: { input: { orders } } });
    //         console.log(data);
    //     } catch (err) {
    //         console.error(err);
    //     }
    // };

    const [ProductName, setProductName] = useState('');
    const handleButtonClick = (weightType, index) => {
        const weightValue = parseFloat(weightType?.value);

        let courierId = '';
        let weightInput = 0;

        switch (true) {
            case weightValue === 0.5:
                courierId = '1';
                weightInput = 500;
                break;
            case weightValue === 1:
                courierId = '12298';
                weightInput = 1000;
                break;
            case weightValue >= 2 && weightValue <= 4:
                courierId = '2';
                weightInput = weightValue * 1000;
                break;
            case weightValue >= 5 && weightValue <= 9:
                courierId = '3';
                weightInput = weightValue * 1000;
                break;
            case weightValue >= 10 && weightValue <= 30:
                courierId = '4';
                weightInput = weightValue * 1000;
                break;
            default:
                break;
        }

        // Update the specific order's details in the orders array
        const updatedOrders = [...orders];
        updatedOrders[index].weightInput = weightInput;
        updatedOrders[index].WeightType = weightType;
        updatedOrders[index].courier_id = courierId;

        setOrders(updatedOrders);
        // Reset priceCalculated for this order
        const updatedPriceCalculated = [...priceCalculated];
        updatedPriceCalculated[index] = false; // Mark the price as not calculated
        setPriceCalculated(updatedPriceCalculated);

    };

    const handleCloseDeliveryDetails = () => {
        setdeliveryModal(false);
        setdeliveryShowModal(true)
    };
    const capitalizeFirstLetter = (text) => {
        if (!text) return text;
        return text.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
    };
    const allPricesCalculated = priceCalculated.every((calculated) => calculated === true);
    const calculateTotalPrice = () => {
        return orders.reduce((total, order) => total + (order?.orderAmount || 0), 0);
    };
    const allBinsHandleChange = (selectedValue, setFieldValue) => {
        setProductName(selectedValue?.label);
        setFieldValue('productCategory', selectedValue);
    };
    const handleCloseAddressDetails = () => {
        setShowModalAddress(false);
        setShowModal(true);
    };
    // const handleRemoveAddress = (index) => {
    //     const updatedForms = addressForms.filter((_, formIndex) => formIndex !== index);
    //     setAddressForms(updatedForms);
    // };
    // const handleRemoveAddress = (index) => {
    //     const updatedOrders = [...orders];  // Create a copy of the orders array
    //     updatedOrders.splice(index, 1);  // Remove the order at the specified index

    //     setOrders(updatedOrders);
    //     // const newOrders = orders.filter((_, i) => i !== index); // Filter out the removed order
    //     // setOrders(newOrders); // Update the orders state

    //     // Also remove the corresponding price calculation
    //     setPriceCalculated(prev => {
    //         const newPriceCalculated = [...prev];
    //         newPriceCalculated.splice(index, 1); // Remove the specific index
    //         return newPriceCalculated;
    //     });
    // };
    const handleRemoveAddress = (index) => {
        if (orders.length <= 1) {
            // Prevent removal if there's only one order
            toast.error("Cannot remove the last order.");
            return;
        }

        // Proceed with removal if there are two or more orders
        const updatedOrders = [...orders];
        updatedOrders.splice(index, 1);
        setOrders(updatedOrders);

        // Update the price calculation state
        setPriceCalculated((prev) => {
            const newPriceCalculated = [...prev];
            newPriceCalculated.splice(index, 1);
            return newPriceCalculated;
        });
    };



    // const handleAddressSelect = (address, index, type) => {
    //     const updatedOrders = [...orders];
    //     if (type === 'pickup') {
    //         updatedOrders[index].selectedPickupAddress = address;
    //         updatedOrders[index].pickupAddress = address.name; // Customize as needed
    //     } else {
    //         updatedOrders[index].selectedDeliveryAddress = address;
    //         updatedOrders[index].deliveryAddress = address.name; // Customize as needed
    //     }
    //     setOrders(updatedOrders);
    // };
    const handleAddressSelect = (address) => {
        const updatedOrders = [...orders];
        updatedOrders[selectedOrderIndex].selectedDeliveryAddress = address;
        setOrders(updatedOrders);
        setdeliveryShowModal(false);
        // Reset priceCalculated for this order
        const updatedPriceCalculated = [...priceCalculated];
        updatedPriceCalculated[selectedOrderIndex] = false; // Mark the price as not calculated
        setPriceCalculated(updatedPriceCalculated);
    };
    const handlePickupAddressSelect = (address) => {
        const updatedOrders = [...orders];
        updatedOrders[selectedOrderIndex].selectedPickupAddress = address;
        setOrders(updatedOrders);
        setShowModal(false);  // Close the modal

        // Reset priceCalculated for this order
        const updatedPriceCalculated = [...priceCalculated];
        updatedPriceCalculated[selectedOrderIndex] = false; // Mark the price as not calculated
        setPriceCalculated(updatedPriceCalculated);
    };
    const createOrderData = async () => {
        // Validate inputs
        const errors = [];
        orders.forEach((order, index) => {
            if (!order?.selectedPickupAddress) errors.push(`Pickup Address for Order ${index + 1}`);
            if (!order?.selectedDeliveryAddress) errors.push(`Delivery Address for Order ${index + 1}`);
            if (!order?.WeightType) errors.push(`Weight Type for Order ${index + 1}`);
            if (!order?.weightInput) errors.push(`Weight Input for Order ${index + 1}`);
            if (!order?.productCategory?.value) errors.push(`Product Name for Order ${index + 1}`);
            if (!order?.ProductPrice) errors.push(`Product Price for Order ${index + 1}`);
        });

        if (errors.length > 0) {
            const errorMessage = `${errors.join(', ')} are required fields.`;
            toast.error(errorMessage);
            return;
        }

        setIsLoading(true);
        try {
            const input = orders.map((order) => ({
                pickupAddress: {
                    warehouse_name: order?.selectedPickupAddress?.warehouse_name,
                    name: order?.selectedPickupAddress?.name,
                    address1: order?.selectedPickupAddress?.address1,
                    city: order?.selectedPickupAddress?.city,
                    stateId: {
                        name: order?.selectedPickupAddress?.stateId,
                    },
                    zipCode: order?.selectedPickupAddress?.zipCode,
                    phone: order?.selectedPickupAddress?.phone,
                },
                deliveryAddress: {
                    name: order?.selectedDeliveryAddress?.name,
                    address1: order?.selectedDeliveryAddress?.address1,
                    city: order?.selectedDeliveryAddress?.city,
                    stateId: {
                        name: order?.selectedDeliveryAddress?.stateId,
                    },
                    zipCode: order?.selectedDeliveryAddress?.zipCode,
                    phone: order?.selectedDeliveryAddress?.phone,
                },
                package_weight: parseFloat(order?.weightInput),
                package_weight_Length: order?.WeightType?.value,
                courier_id: order?.courier_id,
                ProductName: order?.productCategory?.value,
                ProductPrice: order?.ProductPrice,
            }));

            const { data } = await BlulkAddOrder({
                variables: {
                    input,
                }
            });

            // console.log('✌️data --->++++', data?.BlulkAddOrder);
            if (data?.BlulkAddOrder[0]?.paymentSessionId) {
                setIsLoading(false);
                const paymentSessionId = data?.BlulkAddOrder[0]?.paymentSessionId;
                const checkoutOptions = {
                    paymentSessionId,
                    "return_url": RETURN_URL,
                    redirectTarget: "_self",
                };
                cashfree.checkout(checkoutOptions)

            }
        } catch (err) {
            toast.error(err.message);
            setIsLoading(false);
        }
    };

    return (
        <div>
            <Header />
            <div className="mx-auto max-w-auto md:p-6 lg:px-8">
                <div className='flex justify-between'>
                    <h1 className='font-bold text-2xl mb-6 md:px-0 mt-6 px-6'>Bulk Courier Creation</h1>
                    <h1 className='font-semibold mb-6 md:px-0 mt-6 hidden lg:block px-6'>Ship Documents (e.g., Letters, Papers) – Domestic Parcel in India</h1>
                </div>

                <p className='mb-6 px-6  md:px-0'>"Create Bulk Courier Instantly" refers to the ability to process and generate multiple courier shipments at once, allowing businesses or individuals to quickly organize, prepare, and schedule a large volume of parcels for delivery. This feature streamlines the courier creation process, reduces manual entry efforts, and saves time by enabling the bulk upload or input of shipment details, such as addresses, weights, and delivery preferences. This results in faster processing, increased efficiency, and the ability to manage large courier orders more effectively.</p>
                {orders.map((order, index) => (
                    <div key={index} className='bg-gray-50 md:rounded-xl rounded-none border pt-6 pb-3 mb-6 px-6 border-slate-300'>
                        <div className='flex justify-between'>
                            <p className='text- font-bold mb-4'>NO. {index + 1}</p>
                            {/* <button onClick={() => handleRemoveAddress(index)} disabled={index === 0} className="text-slate-500 font-semibold">Close</button> */}
                            <button
                                onClick={() => handleRemoveAddress(index)}
                                // disabled={orders.length === 1 || index === 0}
                                className="text-slate-500 font-semibold">
                                Close
                            </button>

                        </div>
                        <div className=' '>
                            <Formik
                                initialValues={{
                                    orders: [{
                                        ProductPrice: '',
                                        weight: '',
                                        productCategory: { value: '', label: '' },
                                        selectedPickupAddress: null,
                                        selectedDeliveryAddress: null,
                                    }],
                                }}
                                // onSubmit={handleSubmitOrders}
                                validationSchema={validationSchema}

                            >
                                {({ values, setFieldValue }) => (
                                    <Form className="grid grid-cols-1 xl:grid-cols-5 md:grid-cols-3 gap-4">
                                        <>
                                            <div>
                                                <label className="block text-gray-700 text-sm font-medium mb-3" htmlFor="pickupAddress">
                                                    Pickup Address <span className="text-red-500">*</span>
                                                </label>
                                                {!order?.selectedPickupAddress && <div className="relative">
                                                    <input
                                                        name="pickupAddress"
                                                        className="flex w-full cursor-pointer items-center border-slate-500 border rounded-lg py-1.5 px-3 focus:outline-none focus:ring-slate-800 focus:border-slate-800"
                                                        placeholder="Pickup Address"
                                                        onClick={() => {
                                                            setSelectedOrderIndex(index);  // Set the index of the order being updated
                                                            setShowModal(true);            // Open the modal for selecting pickup address
                                                        }}
                                                        readOnly
                                                    />
                                                    <FaCaretRight className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-800" />
                                                </div>}
                                                <ErrorMessage name="pickupAddress" component="div" className="text-red-500 text-sm mt-2" />
                                                {order?.selectedPickupAddress && (
                                                    <ul className="space-y-6 md:space-y-5 mt-3 border bg-white border-slate-300 p-4 rounded-xl ">
                                                        <li className="flex justify-between cursor-pointer items-center text-ellipsis overflow-hidden" onClick={() => {setShowModal(true);  setSelectedOrderIndex(index);}}>
                                                            <div className="flex items-center">
                                                                <div>
                                                                    <div className="space-y-2 mb-1.5">
                                                                        <p className="font-semibold md:text-black text-gray-800 text-sm">{capitalizeFirstLetter(order?.selectedPickupAddress?.name)}</p>
                                                                        <p className="text-sm self-center text-gray-500">+91 {(order?.selectedPickupAddress?.phone)}</p>
                                                                    </div>
                                                                    <div>
                                                                        <p className="text-slate-500 font-normal text-sm">{capitalizeFirstLetter(order?.selectedPickupAddress?.address1)}.</p>
                                                                        <p className="text-slate-500 font-normal text-sm">{capitalizeFirstLetter(order?.selectedPickupAddress?.city)}. {capitalizeFirstLetter(order?.selectedPickupAddress?.stateId)} - {capitalizeFirstLetter(order?.selectedPickupAddress?.zipCode)}</p>
                                                                    </div>
                                                                    <div className='font-semibold mt-4 flex space-x-1 items-center text-green-600' onClick={() => {setShowModal(true); setSelectedOrderIndex(index);}}>
                                                                        <FaRegEdit className='text-lg text-green-600' />
                                                                        <p className='text-xs text-green-600'>ADDRESS UPDATE</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                )}
                                            </div>

                                            <div>
                                                <label className="block text-gray-700 text-sm font-medium mb-3" htmlFor="deliveryAddress">
                                                    Delivery Address <span className="text-red-500">*</span>
                                                </label>
                                                {!order?.selectedDeliveryAddress && <div className="relative">
                                                    <input
                                                        name="deliveryAddress"
                                                        className="flex w-full cursor-pointer items-center border-slate-500 border rounded-lg py-1.5 px-3 focus:outline-none focus:ring-slate-800 focus:border-slate-800"
                                                        placeholder="Delivery Address"
                                                        // onClick={() => setdeliveryShowModal(true)}
                                                        onClick={() => {
                                                            setSelectedOrderIndex(index);
                                                            setdeliveryShowModal(true); // This triggers the modal
                                                        }}
                                                        readOnly
                                                    />
                                                    <FaCaretRight className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-800" />
                                                </div>
                                                }
                                                <ErrorMessage name="deliveryAddress" component="div" className="text-red-500 text-sm mt-2" />
                                                {order?.selectedDeliveryAddress && (
                                                    <ul className="space-y-6 md:space-y-5 mt-3 border bg-white border-slate-300 p-4 rounded-xl ">
                                                        <li className="flex justify-between cursor-pointer items-center text-ellipsis overflow-hidden" onClick={() => {setdeliveryShowModal(true); setSelectedOrderIndex(index);}}>
                                                            <div className="flex items-center">
                                                                <div>
                                                                    <div className='space- y-2 mb-1.5'>
                                                                        <p className="font-semibold md:text-black text-gray-800 text-sm">{capitalizeFirstLetter(order?.selectedDeliveryAddress?.name)}</p>
                                                                        <p className='text-sm self-center text-gray-500'>+91 {(order?.selectedDeliveryAddress?.phone)}</p>
                                                                    </div>
                                                                    <div>
                                                                        <p className="text-slate-500 font-normal text-sm">{capitalizeFirstLetter(order?.selectedDeliveryAddress?.address1)}.</p>
                                                                        <p className="text-slate-500 font-normal text-sm">{capitalizeFirstLetter(order?.selectedDeliveryAddress?.city)}.  {capitalizeFirstLetter(order?.selectedDeliveryAddress?.stateId)} - {capitalizeFirstLetter(order?.selectedDeliveryAddress?.zipCode)}</p>
                                                                    </div>
                                                                    <div className='font-semibold mt-4 flex space-x-1 items-center text-green-600' onClick={() =>{ setdeliveryShowModal(true); setSelectedOrderIndex(index);}}>
                                                                        <FaRegEdit className='text-lg text-green-600' />
                                                                        <p className='text-xs text-green-600'>ADDRESS UPDATE</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                )}
                                            </div>

                                            <div>
                                                <label className=' text-gray-700 text-sm font-medium mb-3'>Weight (Kg) <span className="text-red-500">*</span></label>
                                                <div className="mt-2">
                                                    {/* <Field name="weight">
                                                        {({ field, form }) => {
                                                            const selectedOption = weightOptions.find(option => option.value === field.value);
                                                            return (
                                                                <Select
                                                                    value={selectedOption}
                                                                    options={weightOptions}
                                                                    className="w-full focus:outline-none"
                                                                    styles={{
                                                                        control: (base, state) => ({
                                                                            ...base,
                                                                            borderColor: state.isFocused ? '#64748B' : '#64748B',
                                                                            '&:hover': { borderColor: '#64748B' },
                                                                            boxShadow: state.isFocused ? '0 0 0 0px #000000' : 'none',
                                                                            borderRadius: '0.375rem',
                                                                        }),
                                                                        singleValue: (base) => ({
                                                                            ...base,
                                                                            color: '#000000',
                                                                        }),
                                                                        option: (base, state) => ({
                                                                            ...base,
                                                                            backgroundColor: state.isSelected ? '#000000' : state.isFocused ? '#f0f0f0' : 'white',
                                                                            color: state.isSelected ? 'white' : '#000000',
                                                                            '&:hover': {
                                                                                backgroundColor: '#f0f0f0',
                                                                                color: '#000000',
                                                                            }
                                                                        }),
                                                                        placeholder: (base) => ({
                                                                            ...base,
                                                                            fontSize: '13.7px',
                                                                            color: '#A3AAB5',
                                                                        }),
                                                                    }}
                                                                    isSearchable={false}
                                                                    placeholder="Select Weight"
                                                                    onChange={option => {
                                                                        form.setFieldValue(field.name, option.value);
                                                                        handleButtonClick(option.value);
                                                                    }}
                                                                    onBlur={() => form.setFieldTouched(field.name, true)}
                                                                />
                                                            );
                                                        }}
                                                    </Field> */}
                                                    {/* <Field name={`orders[${index}].weight`}>
                                                        {({ field, form }) => {
                                                            const selectedOption = weightOptions.find(option => option?.value === field?.value);
                                                            return ( */}
                                                    <Select
                                                        // value={selectedOption}
                                                        value={orders[index].weight}
                                                        options={weightOptions}
                                                        onChange={option => {
                                                            const updatedOrders = [...orders];
                                                            updatedOrders[index].weight = option;
                                                            setOrders(updatedOrders);  // Update the specific order
                                                            handleButtonClick(option, index);
                                                        }}
                                                        className="w-full focus:outline-none"
                                                        styles={{
                                                            control: (base, state) => ({
                                                                ...base,
                                                                borderColor: state.isFocused ? '#64748B' : '#64748B',
                                                                '&:hover': { borderColor: '#64748B' },
                                                                boxShadow: state.isFocused ? '0 0 0 0px #000000' : 'none',
                                                                borderRadius: '0.375rem',
                                                            }),
                                                            singleValue: (base) => ({
                                                                ...base,
                                                                color: '#000000',
                                                            }),
                                                            option: (base, state) => ({
                                                                ...base,
                                                                backgroundColor: state.isSelected ? '#000000' : state.isFocused ? '#f0f0f0' : 'white',
                                                                color: state.isSelected ? 'white' : '#000000',
                                                                '&:hover': {
                                                                    backgroundColor: '#f0f0f0',
                                                                    color: '#000000',
                                                                }
                                                            }),
                                                            placeholder: (base) => ({
                                                                ...base,
                                                                fontSize: '13.7px',
                                                                color: '#A3AAB5',
                                                            }),
                                                        }}
                                                        isSearchable={false}
                                                        placeholder="Select Weight"

                                                    // onBlur={() => form.setFieldTouched(field.name, true)}
                                                    />
                                                    {/* );
                                                        }}
                                                    </Field> */}

                                                    <ErrorMessage name="weight" component="div" className="text-red-600 text-sm mt-1 z-50" />
                                                </div>
                                                {/* <ErrorMessage name="ProductPrice" component="div" className="text-red-500 text-sm mt-2" /> */}
                                            </div>

                                            <div>
                                                <label htmlFor="ItemSelect" className="block text-sm font-medium text-gray-700 mb-3">
                                                    Courier Category <span className="text-red-500">*</span>
                                                </label>

                                                <Select
                                                    // value={orders[index].productCategory}
                                                    // value={orders[index].productCategory.value || null}
                                                    options={options}
                                                    className="basic-single ffPoppins"
                                                    onChange={(selectedValue) => {
                                                        const updatedOrders = [...orders];
                                                        updatedOrders[index].productCategory = selectedValue;
                                                        setOrders(updatedOrders);
                                                    }}
                                                    // className="w-full focus:outline-none"
                                                    styles={{
                                                        control: (base, state) => ({
                                                            ...base,
                                                            borderColor: state.isFocused ? '#64748B' : '#64748B',
                                                            '&:hover': { borderColor: '#64748B' },
                                                            boxShadow: state.isFocused ? '0 0 0 0px #000000' : 'none',
                                                            borderRadius: '0.375rem',
                                                        }),
                                                        singleValue: (base) => ({
                                                            ...base,
                                                            color: '#000000',
                                                        }),
                                                        option: (base, state) => ({
                                                            ...base,
                                                            backgroundColor: state.isSelected ? '#000000' : state.isFocused ? '#f0f0f0' : 'white',
                                                            color: state.isSelected ? 'white' : '#000000',
                                                            '&:hover': {
                                                                backgroundColor: '#f0f0f0',
                                                                color: '#000000',
                                                            },
                                                        }),
                                                        placeholder: (base) => ({
                                                            ...base,
                                                            fontSize: '13.7px',
                                                            color: '#A3AAB5',
                                                        }),
                                                    }}
                                                    isSearchable={false}
                                                    placeholder="Select Package Category"
                                                />

                                                <ErrorMessage name="productCategory.value" component="div" className="text-red-500 text-sm mt-2" />
                                            </div>

                                            <div className="mb-4">
                                                <label htmlFor="ProductPrice" className="block text-sm font-medium text-gray-700 mb-3">
                                                    Courier Value Rs.<span className="text-red-500">*</span>
                                                </label>
                                                {/* <div className="mt-1 flex rounded-md shadow-sm">
                                                    <input
                                                        type="number"
                                                        id="ProductPrice"
                                                        name="ProductPrice"
                                                        // onChange={(e) => {
                                                        //     const value = e.target.value;
                                                        //     if (value.length <= 4) {
                                                        //         setFieldValue('ProductPrice', value);
                                                        //         setProductPrice(value);
                                                        //     }
                                                        // }}
                                                        onWheel={(e) => e.target.blur()}
                                                        className="flex-1 block w-full px-3 py-1.5 border border-slate-500 rounded-md focus:outline-none focus:ring-black focus:border-black"
                                                        placeholder="Enter Value Ex.Rs.199"
                                                    />
                                                </div> */}
                                                <input
                                                    type="number"
                                                    id="ProductPrice"
                                                    name={`orders[${index}].ProductPrice`}
                                                    value={orders[index].ProductPrice}  // Bind this field to the current order's price
                                                    onChange={(e) => {
                                                        const updatedOrders = [...orders];
                                                        updatedOrders[index].ProductPrice = e?.target?.value;
                                                        setOrders(updatedOrders);  // Update the specific order's price
                                                    }}
                                                    onWheel={(e) => e.target.blur()}
                                                    className="flex-1 block w-full px-3 py-1.5 border border-slate-500 rounded-md focus:outline-none focus:ring-black focus:border-black"
                                                    placeholder="Enter Value Ex.Rs.199"
                                                />

                                                {/* <ErrorMessage name="ProductPrice" component="div" className="text-red-500 text-sm mt-2" /> */}
                                            </div>
                                        </>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                        {/* {priceCalculated[index] ? (
                            <p className='text-end font-bold mt-4'>INR ₹{Math.round(order.orderAmount)}</p>
                        ) : (
                            <button
                                onClick={() => handleCalculateCourier(index)}
                                className="px-6 py-2 bg-gray-100 text-black text-end text-sm rounded-lg hover:bg-gray-200 font-semibold transition duration-300">
                                Show Price
                            </button>
                        )} */}
                        {/* {orders?.map((order, index) => ( */}
                        <div key={index} className="flex justify-end">
                            {/* {isLoadingData[index] && (
                                <>
                                    <span>Loading...</span>
                                    <div className="loader ml-2"></div> 
                                </>
                            )}
                            {priceCalculated[index] ? (
                                <p className="text-end font-bold">INR ₹{Math.round(order?.orderAmount)}</p>
                            ) : (
                                <button
                                    onClick={() => handleCalculateCourier(index)}

                                    className="px-6 py-2 bg-slate-800 text-white text-sm rounded-lg hover:bg-slate-900 font-semibold transition duration-300">

                                    Show Price

                                </button>
                            )} */}

                            {isLoadingData[index] ? ( // Show loader if calculation is in progress
                                <>
                                    <span>Loading...</span>
                                    <div className="loader ml-2"></div>
                                </>
                            ) : priceCalculated[index] ? ( // Show price if calculation is complete
                                <p className="text-end font-bold">INR ₹{Math.round(order?.orderAmount)}</p>
                            ) : ( // Show button if calculation has not started
                                <button
                                    onClick={() => handleCalculateCourier(index)}
                                    className="px-6 py-2 bg-slate-800 text-white text-sm rounded-lg hover:bg-slate-900 font-semibold transition duration-300">
                                    Show Price
                                </button>
                            )}
                        </div>
                        {/* ))} */}



                    </div>
                ))}
                <div className='flex justify-between px-6 md:px-0 flex-col md:flex-row space-y-3 md:space-y-0'>
                    <button onClick={handleAddOrder} className="px-6 py-2 bg-gray-200 text-black text-sm rounded-lg hover:bg-gray-300 font-semibold transition duration-300">
                        ADD MORE ADDRESS
                    </button>
                    <div className="relative group">
                        <button
                            type="submit"
                            onClick={createOrderData}
                            disabled={isLoading || orders.length === 0 || !allPricesCalculated}
                            className={`${orders.length === 0 || !allPricesCalculated ? 'bg-slate-400 text-white rounded-lg cursor-not-allowed font-semibold transition duration-300' : 'bg-slate-800 text-white rounded-lg hover:bg-slate-900 font-semibold transition duration-300'} 
                px-4 py-2.5 md:px-8 md:py-1.5 lg:px-12 lg:py-1.5 w-full md:w-auto`}
                        >
                            <p className="flex items-center justify-center text-sm md:text-base lg:text-lg">
                                {isLoading ? (
                                    <>
                                        <span>Loading...</span>
                                        <SpinLoader />
                                    </>
                                ) : (
                                    `PAY ₹${calculateTotalPrice()}`
                                )}
                            </p>
                        </button>
                        {/* Tooltip */}
                        {isLoading || orders.length === 0 || !allPricesCalculated && (
                            <div className="absolute mt-0.5 transform -translate-x-1/2 mb-0.5 px-3 py-1 text-xs text-white bg-slate-500 rounded shadow-lg opacity-0 group-hover:opacity-100 transition duration-200">
                                {"Please click the 'Show Price' button first. Once the price is displayed, the 'Pay' button will become available."}
                            </div>
                        )}

                    </div>
                </div>

                <div className='lg:flex lg:flex-row flex-col-reverse lg:space-x-7 mt-10 px-6 md:px-0' >
                    <img src="https://www.acepalletracking.com/uploads/2021072101.jpg" className='rounded-xl h-96 hover:opacity-75 cursor-pointer' alt="" />
                    <div className='justify-center content-center lg:px-10 space-y-7'>
                        <p className='text-xl font-semibold mt-4'>Effortless Bulk Courier Solutions for UK Logistics Businesses</p>
                        <p>Creating instant bulk courier services for UK logistics involves developing a system that efficiently handles large-scale, time-sensitive deliveries across the country. This service would enable businesses to ship multiple packages or large quantities of goods at once, while ensuring swift processing, tracking, and delivery. Leveraging advanced technology, such as automated order processing, real-time GPS tracking, and optimized routing, the system would streamline operations for e-commerce, retail, and industrial sectors. The focus would be on reliability, speed, and cost-effectiveness, allowing companies to meet high-demand delivery schedules without sacrificing service quality or customer satisfaction. This service would play a vital role in supporting the fast-paced and growing logistics industry in the India.</p>
                    </div>
                </div>

                <div className='lg:flex lg:flex-row flex-col-reverse lg:space-x-7 mt-10 px-6 md:px-0' >
                    <div className='justify-center content-center lg:px-10 space-y-7'>
                        <p className='text-xl font-semibold '>Create Multiple Courier One by One with Our Latest Technology</p>
                        <p>Our cutting-edge courier service allows you to create multiple shipping orders individually, giving you the flexibility and control you need for each delivery. With our latest courier technology, you can easily manage shipments one by one, ensuring that each order is tailored to your specific requirements, whether it’s package size, delivery time, or destination. Our user-friendly platform simplifies the process, while advanced features like automated tracking, optimized routing, and digital proof of delivery ensure smooth and reliable operations. By leveraging this technology, we help businesses streamline logistics and improve efficiency, providing a modern, tech-driven solution for all your courier needs. Whether you're sending local or international parcels, our service ensures fast, secure, and efficient deliveries, saving you time while enhancing your overall shipping experience.</p>
                    </div>
                    <img src="https://www.acepalletracking.com/uploads/2021072102.jpg" className='rounded-xl hover:opacity-75 cursor-pointer mt-4 h-96' alt="" />
                </div>

                <div className='lg:flex lg:flex-row flex-col-reverse lg:space-x-7 mt-10 px-6 md:px-0'>
                    <img src="https://www.acepalletracking.com/uploads/2021072103.jpg" className='rounded-xl hover:opacity-75 cursor-pointer h-96' alt="" />
                    <div className='justify-center content-center lg:px-10 space-y-7'>
                        <p className='text-xl font-semibold mt-4'>Create Multiple Courier Orders and Save Time Effortlessly</p>
                        <p >Our service allows you to create multiple courier orders in just a few clicks, streamlining your logistics and saving valuable time. Designed to simplify the process of booking multiple shipments, our platform enables businesses to handle large volumes of parcels without the hassle of repeated data entry or manual scheduling. With features like automated bulk order processing, real-time shipment tracking, and flexible delivery options, you can manage all your courier needs in one place. By minimizing the time spent on administrative tasks, our service frees you up to focus on what matters most—growing your business. Whether you're shipping to local or international destinations, we provide a fast, reliable, and efficient solution that meets your business's needs.</p>
                    </div>
                </div>


                <p className='mt-10 text-sm px-6 md:px-0'> Our platform offers the most cost-effective solution for bulk courier pickups and deliveries, ensuring you get the lowest prices on every shipment. With our all-in-one system, you can schedule multiple pickups and deliveries effortlessly, manage all your orders in one place, and track everything in real-time. No more juggling between different couriers or dealing with complex logistics—our platform streamlines the entire process. By consolidating your shipments and using our optimized routing technology, we help you cut costs and save valuable time. Whether you're a small business or a large enterprise, we’re here to help you save money while ensuring your packages reach their destination quickly and securely. Start saving today with our budget-friendly bulk courier service!</p>

            </div>


            <AddressModal data={getReceiverData} refetch={reciverRefetch} show={showModal} onClose={() => setShowModal(false)}
                setShowModal={setShowModal} setShowModalAddress={setShowModalAddress} setSelectedPickupAddress={setSelectedPickupAddress}

                onSelectAddress={(address) => handlePickupAddressSelect(address)} />
            <AddressDetailsModel onSelectAddress={(address) => handlePickupAddressSelect(address)} refetch={reciverRefetch} show={showModalAddress} onClose={handleCloseAddressDetails} setShowModalAddress={setShowModalAddress} setShowModal={setShowModal} setPickupAddress={setPickupAddress} pickupAddress={pickupAddress} setSelectedPickupAddress={setSelectedPickupAddress} />
            <DeliveryDetailsModel onSelectAddress={(address) => handleAddressSelect(address)} refetch={refetch} show={deliveryModal} onClose={handleCloseDeliveryDetails} setdeliveryModal={setdeliveryModal} setdeliveryShowModal={setdeliveryShowModal} setDeliveryAddress={setDeliveryAddress} deliveryAddress={deliveryAddress} setSelectedDeliveryAddress={setSelectedDeliveryAddress} />
            <DeliveryModal refetch={refetch} show={deliveryshowModal} data={getDeliveryData} onClose={() => setdeliveryShowModal(false)} setdeliveryShowModal={setdeliveryShowModal} setdeliveryModal={setdeliveryModal} setSelectedDeliveryAddress={setSelectedDeliveryAddress}
                // onSelectAddress={(address) => handleAddressSelect(address, selectedOrderIndex, 'delivery')}
                onSelectAddress={(address) => handleAddressSelect(address)}
            />

            <div className='mt-10'>
                <Footer />
            </div>
        </div >
    )
}

export default BulkCourier
