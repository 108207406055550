import React, { useEffect, useState } from 'react'
import { FaBoxOpen } from "react-icons/fa6";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { IoBicycleOutline } from "react-icons/io5";
import { SlEnvolope } from "react-icons/sl";
import { BsBoxSeam,BsBoxes } from "react-icons/bs";
import { PiAirplaneTilt } from "react-icons/pi";
import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useQuery } from '@apollo/client';
import { handleLogin } from '../Common/TokenLogin.js';
import { toast } from 'react-toastify';
import { GET_VALUATION } from '../graphql/Mutation/Topbar.js';
import SpinLoader from '../components/SpinLoader.js';
import TrackingHistoryModal from '../TrackCourier/TrackingHistoryModal.js';
import { CiBoxes } from "react-icons/ci";

const Mainone = () => {
  const { data: getvaluation, refetch: refetchValuation } = useQuery(GET_VALUATION);
  const [modalOpen, setModalOpen] = useState(false);
  const [shipmentData, setShipmentData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const validationSchema = Yup.object({
    awb: Yup.string().required('AWB number is required'),
  });

  const initialValues = {
    awb: '',
  };
  const handleTrackShipment = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const authToken = await handleLogin();
    const apiUrl = `https://shipment.xpressbees.com/api/shipments2/track/${values.awb}`;
    setIsLoading(true);
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        }
      });
      const data = await response.json();
      if (data?.status) {
        if (data?.data?.status === "cancelled") {
          toast.error('Courier Not Found!');
        } else {
          setShipmentData(data?.data);
          setModalOpen(true);
        }
      } else {
        toast.error('Courier Not Found!');
      }


    } catch (error) {
      toast.error(`Error tracking shipment: ${error.message}`);
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  }
  const handleChange = (e, setFieldValue) => {
    const cleanedValue = e.target.value.replace(/\s+/g, '');
    setFieldValue('awb', cleanedValue);
  };
  const links = [
    { name: 'Open roles', href: '#' },
    { name: 'Internship program', href: '#' },
    { name: 'Our values', href: '#' },
    { name: 'Meet our leadership', href: '#' },
  ]
  return (
    <div>

      {/* <video
        controls
        autoPlay
        loop
        muted
        className='h-20 w-full'
      >
        <source src={sampleVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}
  

      <div className="relative isolate overflow-hidden bg-gray-900 pt-4 pb-11 sm:pb-16 sm:pt-9">
        <img
          src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
        />
        <div
          className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div
          className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
          aria-hidden="true"
        >
          <div
            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className="mx-auto max-w-auto  md:px-8 px-0">
          <div className="mx-auto max-w-2xl pt-4 sm:py-6 lg:max-w-none md:pt-5">
            <div className="mx-auto md:px-0 px-6 lg:mx-0">
              <h2 className="text-2xl font-bold tracking-tight sm:text-2xl text-white">Get your shipment picked up
                from your doorstep</h2>
              <p className="mt-2 leading-8 text-white">
                Send your parcel from the comfort of your home or office
              </p>
            </div>

            <div className="mb-7 mt-7 lg:mt-7 space-y-10 lg:grid lg:grid-cols-3  lg:gap-x-3 lg:space-y-0 ">
              <div className='md:rounded-lg rounded-none md:p-6 py-6 px-6  ring-1 ring-slate-900/5 shadow-md md:shadow-md bg-white justify-center'>
                <h2 className="text-xl font-bold text-gray-900">Ship Now </h2>
                <p className='text-slate-500 text-sm mt-1 mb-5'>We arrive at your doorstep
                  Our partners reach your location within 24 hours of placing the order.</p>
                <div className='grid grid-cols-3 md:grid-cols-3 md:gap-y-5 gap-y-4 lg:grid-cols-3 xl:grid-cols-3'>

                  <Link to='/documentshipping' state={{ key: false }}>
                    <div className=' hover:opacity-75 cursor-pointer'>
                      <SlEnvolope className='text-5xl text-gray-800' />
                      <p className='font-semibold text-xs xl:text-sm text-slate-500'>Document</p>
                    </div></Link>
                  <Link to='/documentshipping' state={{ key: true }}>
                    <div className='hover:opacity-75 cursor-pointer'>
                      <BsBoxSeam className='text-5xl p-1.5 text-gray-800 ' />
                      <p className='font-semibold  text-xs xl:text-sm text-slate-500'>0 to 30Kg</p>
                    </div></Link>
                    <Link to='/bulkcourier'>
                    <div className='hover:opacity-75 cursor-pointer'>
                      <BsBoxes className='text-5xl p-1.5 text-gray-800 ' />
                      <p className='font-semibold  text-xs xl:text-sm text-slate-500'>Bulk 0 to 30Kg</p>
                    </div></Link>
                  <Link to='/foreignair'>
                    <div className=' xl:mt-0 hover:opacity-75 cursor-pointer'>
                      <PiAirplaneTilt className='text-5xl text-gray-800  rotate-45 p-1' />
                      <p className='font-semibold  text-xs xl:text-sm text-slate-500'>Foreign Air</p>
                    </div></Link>
                    <Link to='/sameday'> <div className='hover:opacity-75 cursor-pointer'>
                    <IoBicycleOutline className='text-5xl p-0.5 text-gray-800 ' />
                    <p className='font-semibold  text-xs xl:text-sm text-slate-500'>Same Day</p>
                  </div></Link>
                </div>
                <p className='text-sm font-semibold mt-7 md:block'>Book and track orders
                  anytime, anywhere</p>
              </div>

              <div className='md:rounded-lg  rounded-none md:p-6 py-6 px-6 ring-1 ring-slate-900/5 bg-white shadow-md md:shadow-md'>
                <h2 className="text-xl font-bold text-gray-900">Estimate Price?</h2>
                <p className='text-slate-500 text-sm mt-1 mb-5'>Reliable, Pan-India shipping
                  Quick pick-up and fast delivery across all over india.</p>
                <div className='grid grid-cols-3 md:grid-cols-3 md:gap-y-5 gap-y-4 lg:grid-cols-3 xl:grid-cols-3 '>
                  <Link to='/calculatorcourier' state={{ keyy: false }}>
                    <div className=' hover:opacity-75 cursor-pointer'>
                      <SlEnvolope className='text-5xl text-gray-800' />
                      <p className='font-semibold text-xs xl:text-sm text-slate-500'>Document</p>
                    </div>
                  </Link>
                  <Link to='/calculatorcourier' state={{ keyy: true }}>
                    <div className='hover:opacity-75 cursor-pointer'>
                      <BsBoxSeam className='text-5xl p-1.5 text-gray-800 ' />
                      <p className='font-semibold  text-xs xl:text-sm text-slate-500'>0 to 30Kg</p>
                    </div>
                  </Link>
                  <Link to='/foreignair'>
                    <div className=' xl:mt-0 hover:opacity-75 cursor-pointer'>
                      <PiAirplaneTilt className='text-5xl text-gray-800  rotate-45 p-1' />
                      <p className='font-semibold  text-xs xl:text-sm text-slate-500'>Foreign Air</p>
                    </div></Link>
                    <Link to='/sameday'>  <div className='hover:opacity-75 cursor-pointer'>
                    <IoBicycleOutline className='text-5xl p-0.5 text-gray-800 ' />
                    <p className='font-semibold  text-xs xl:text-sm text-slate-500'>Same Day</p>
                  </div></Link>
                </div>
                <p className='text-sm font-semibold mt-7 md:block'>Fastest and reliable shipping anytime, anywhere</p>
              </div>
              <div className="md:rounded-lg rounded-none p-6 py-6 px-6 ring-1 bg-white ring-slate-900/5 shadow-md md:shadow-md space-y-4 md:space-y-3">
                <div className="flex items-center space-x-3">
                  <FaBoxOpen className="h-6 w-6" />
                  <h3 className="text-slate-900 text-md font-semibold">Can't find your delivery?</h3>
                </div>
                <p className="text-slate-500 text-sm mb-4">Find your delivery using your AWB Number</p>
                <div className="sm:col-span-4">
                  <p className='text-md font-semibold'>AWB Number <span className="text-red-500">*</span></p>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleTrackShipment}
                  >
                    {({ isSubmitting, setFieldValue }) => (
                      <Form>
                        <div className="mt-3 flex">
                          <Field
                            type="text"
                            name="awb"
                            id="awb"
                            autoComplete="awb"
                            className="flex-1 border-0 rounded-md shadow-sm ring-1 ring-inset focus:outline-none focus:ring-slate-600 focus:border-slate-600 ring-slate-500 py-2 px-3 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            placeholder="Enter AWB Number"
                            onChange={(e) => handleChange(e, setFieldValue)}
                          />

                          <button
                            type="submit"
                            disabled={isSubmitting || isLoading}
                            className="my-auto ms-3 text-2xl hover:text-black"
                          >
                            {isLoading ? (

                              <SpinLoader />

                            ) : (
                              <FaRegArrowAltCircleRight />
                            )}
                          </button>
                        </div>
                        <ErrorMessage name="awb" component="div" className="text-red-500 text-sm mt-1" />
                      </Form>
                    )}
                  </Formik>
                  <p className='text-sm  mt-6 md:block'>Real-Time Tracking:
                  Once your package is on its way, you can track its journey in real time. Receive updates via email or through our mobile app, so you always know where your shipment is and when it’s expected to arrive. Our tracking system provides detailed information at every stage of the delivery process, offering peace of mind and transparency.</p>
                </div>

                {modalOpen && shipmentData && (
                  <TrackingHistoryModal
                    trackingInfo={shipmentData}
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="mx-auto mt-1 md:mt-0 max-w-2xl px-6 md:px-0 lg:mx-0 lg:max-w-none">
            <dl className="grid grid-cols-1 gap-8 md:mt-13 sm:grid-cols-2 lg:grid-cols-5">
              {getvaluation?.getTopBarValuation?.length > 0 && getvaluation?.getTopBarValuation?.map((stat) => (
                <div key={stat.topBar} className="flex flex-col-reverse">
                  <dt className="text-base leading-7 text-gray-300">{stat?.topBar}</dt>
                  <dd className="text-2xl font-bold leading-9 tracking-tight text-white">{stat?.content}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Mainone
