import { gql } from "@apollo/client";

export const CANCEL_ORDER =gql`
mutation Mutation($awbNumber: String!) {
  cancelCourier(awbNumber: $awbNumber) {
    id
    customerId
    order_number
    pickupAddress {
      name
      phone
      address1
      city
      stateId {
        id
        name
      }
      zipCode
      customerId
    }
    deliveryAddress {
      name
      phone
      address1
      city
      zipCode
      customerId
    }
    pickupDate
    stateId
    trackStatus
    order_amount
    package_weight
    isDeleted
    isActive
    status
    courier_name
    manifest
    shipment_id
    order_id
    courier_id
    token
    label
    createdBy
    awb_number
    updatedBy
    createdAt
    updatedAt
  }
}
`

export const CREATE_ORDER = gql`
mutation Mutation($input: OrderInput!) {
  addOrder(input: $input) {
    id
    customerId
    order_number
    pickupAddress {
      name
      phone
      address1
      city
      stateId {
        id
        name
      }
      zipCode
      customerId
    }
    deliveryAddress {
      name
      phone
      address1
      city
      zipCode
       stateId {
        id
        name
      }
      customerId
    }
    pickupDate
    stateId
    trackStatus
    order_amount
    package_weight
    isDeleted
    ProductPrice
    ProductName
    isActive
    status
    courier_name
    package_weight_Length
    manifest
    shipment_id
    order_id
    courier_id
    paymentSessionId
    cfToken
    cfOrderId
    paymentStatus
    label
    createdBy
    awb_number
    updatedBy
    createdAt
    updatedAt
    updatedAt
  }
}
`;
export const BULK_ADD_ORDER = gql`
  mutation BlulkAddOrder($input: [OrderInput]) {
    BlulkAddOrder(input: $input) {
       id
    customerId
    order_number
    pickupAddress {
      name
      phone
      address1
      city
      stateId {
        id
        name
      }
      zipCode
      customerId
    }
    deliveryAddress {
      name
      phone
      address1
      city
      zipCode
       stateId {
        id
        name
      }
      customerId
    }
    pickupDate
    stateId
    trackStatus
    order_amount
    package_weight
    isDeleted
    ProductPrice
    ProductName
    isActive
    status
    courier_name
    package_weight_Length
    manifest
    shipment_id
    order_id
    courier_id
    paymentSessionId
    cfToken
    cfOrderId
    paymentStatus
    label
    createdBy
    awb_number
    updatedBy
    createdAt
    updatedAt
    updatedAt
    }
  }
`;

export const ORDER_MENIFETCH = gql`
mutation OrderMenifetch($orderId: String) {
  orderMenifetch(orderId: $orderId)
}
`


export const REFUND_ORDER_MUTATION = gql`
  mutation RefundOrder($orderId: String, $refundAmount: Float) {
    refundOrder(orderId: $orderId, refundAmount: $refundAmount) {
      success
      message
    }
  }
`;