import React, { useEffect } from 'react'
import Header from '../Header/Header.js'
import Footer from '../Footer/Footer.js'
import { useState } from 'react'
import { BiSolidDownArrow, BiSolidRightArrow } from "react-icons/bi";
import { useMutation } from '@apollo/client'
import { CREATE_SUPPORT } from '../graphql/Mutation/Support.js'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { IoMdCall, IoMdMail } from "react-icons/io";
import { IoEarth } from "react-icons/io5";
import useScrollToTop from '../components/useScrollToTop.js';

// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ')
// }

const navigation = {
  categories: [
    {
      name: 'Shipment Status',
      sections: [
        {
          name: 'How can I track my shipment?',
          items:
            { name: 'To track your shipment, you can download our app or go to the tracking page. Entering your tracking ID (AWB/LRN) and click on TRACK. You can also login using your mobile number to view all shipments being delivered to you by Delhivery and reach out to our customer support.' },

        },
        {
          name: 'Why is my package delayed?',
          items:
            { name: 'Your delivery may get delayed due to unforeseen circumstances. A new delivery timeframe will be shared with you when you track your shipment on our website. In case you have additional questions or concerns, please raise a support ticket from here .' },

        },
        {
          name: 'My shipment status is still at (Pick up) What should I do?',
          items:
            { name: 'Pick up pending status means that Delhivery hasnt received the physical shipment from the sender. Please contact the sender for further assistance.' },

        }, {
          name: 'What do I do if tracking page doesnt show the expected date of arrival?',
          items:
            { name: 'Delhivery will be able to provide estimated date of arrival only after the shipment has been picked up from the sender. In the meantime, please contact the sender for further assistance.' },

        }, {
          name: 'My shipment is out for delivery. When will I receive it?',
          items:
            { name: 'Your shipment can be delivered to you anytime between 10:00 am and 8:00 pm on the same day when it is marked as out for delivery.' },

        }, {
          name: 'How can I get the delivery of my shipment if I missed it today?',
          items:
            { name: 'Do not worry if you missed your order. Delhivery usually attempts to deliver the shipment on the following business day if you have missed a delivery.' },

        }, {
          name: 'Why can I see no update to the status of my shipment?',
          items:
            { name: 'Delhivery tracking updates are accurate and real time. However, if due to unforeseen circumstances, your shipment has not moved in the last few days, you can raise a support ticket here.' },

        }, {
          name: 'How can I get the contact number of my delivery/pickup partner?',
          items:
            { name: 'You can find the contact details of the delivery/pickup partner from the tracking details section of our Mobile app. This feature is only available once the shipment is out for delivery/pickup.' },

        }, {
          name: 'How can I reschedule delivery/pickup for my order?',
          items:
            { name: 'You can reschedule your delivery/pickup from the tracking details section of our Mobile app. However, this facility is not available once the package is out for delivery/pickup. If our executive reaches your doorstep, please refuse to accept / handover the package.' },

        },
      ],
    },
    // ==============================================================================
    {
      name: 'Destination and Contact details',
      sections: [
        {
          name: 'How do I change my delivery address after an order was placed?',
          items:
            { name: 'As a delivery partner, we cannot change your address at our end. Please contact the seller at your end to change your delivery address. For Direct orders, you can change the delivery address from the order details section of our Mobile app.' },
        },
        {
          name: 'Why I am not getting any tracking details on my phone? ',
          items:
            { name: 'You can get real time updates on your shipments by downloading our Mobile app and enabling notifications on it.' },
        },
        {
          name: 'What should I do if I am not available at the time of delivery/pickup?',
          items:
            { name: 'You can reschedule your delivery/pickup from the tracking details section of our Mobile app. However, this facility is not available once the package is out for delivery/pickup. If our executive reaches your doorstep, please refuse to accept / handover the package. ' },

        }, {
          name: 'How can I collect the shipment myself from Delhivery facility?',
          items:
            { name: 'We prefer delivering your shipment at your doorstep. However, if you wish to collect it yourself, you can place a request from the shipment details page of our Mobile app. You need to carry any Government ID proof (PAN card, driving license, voter ID) to the Delhivery facility to collect your order. This facility is not available when the package is out for delivery. If our executive reaches your doorstep, please refuse to accept / handover the package.' },

        }, {
          name: 'What documents should I carry to self-collect my shipment?',
          items:
            { name: 'You need to carry any Government ID proof (PAN card, driving license, voter ID).' },

        }, {
          name: 'Can I open my package before accepting it?',
          items:
            { name: 'We understand that you wish to ensure that the shipment is as per your expectation before accepting it. However, currently, we do not provide an open delivery service. In case of any product related issue, please contact the sender.' },

        }, {
          name: 'How can I add an alternate phone number to my order?',
          items:
            { name: 'You can add an alternate phone number from the order details section of our Mobile app. However, this facility is not available once the package is out for delivery/pickup.' },

        },
      ],
    },
    // ====================================================
    {
      name: 'Returns ',
      sections: [
        {
          name: 'What do I do if there is a delay in the pick up of my return shipment?',
          items:
            { name: 'Due to unforeseen circumstances, your pick up can get delayed. Please raise a support ticket from the tracking page here.' },

        }, {
          name: 'How do I cancel my delivery/pickup? ?',
          items:
            { name: 'You can cancel your delivery/pickup from the shipment details section of our Mobile app. However, this facility is not available once the package is out for delivery/pickup. If our executive reaches your doorstep, please refuse to accept / handover the package.' },

        },
        {
          name: 'What do I do if I havent received the refund of my returned shipment? ',
          items:
            { name: 'As a delivery partner, we do not have any refund related information. We request you to contact the sender for further assistance.' },

        },
        {
          name: 'My shipment has been returned/cancelled. What should I do?',
          items:
            { name: 'Please contact the sender with whom the order was placed for further clarification.' },

        },
      ],
    },
    // ========================================================
    {
      name: 'Report Issues',
      sections: [
        {
          name: 'I did not get my shipment but it is marked delivered. What do I do??',
          items:
            { name: 'Please check with your family members or neighbours in case they have received the shipment on your behalf. If they have not received the shipment, please raise a support ticket from here and our support team will investigate the issue.' },

        },
        {
          name: 'What do I do on receiving a wrong/damaged/incorrect shipment? ',
          items:
            { name: 'Company is a logistics partner to the sender and hence cannot guarantee product quality at their end. We assure you that all the shipments in our network will reach you untampered and in the same condition as was handed over to us by the sender. Please contact the sender or the marketplace to raise any product related issue.' },

        },
        {
          name: 'My Cash On Delivery (COD) link was disabled at the time of delivery. How do I pay?',
          items:
            { name: 'We regret the inconvenience caused to you due to this. In this case you can pay with cash/UPI/QR Code.' },

        }, {
          name: 'What if my shipment is marked as lost?',
          items:
            { name: 'If a shipment is lost in our network, we immediately intimate the sender with the shipment status. Please get in touch with the sender, and you will be remitted accordingly.' },

        },
      ],
    },
    // ======================================
    {
      name: 'Report on-field staff issues',
      sections: [
        {
          name: 'What do I do if my pickup was cancelled without my consent?',
          items:
            { name: 'We regret the inconvenience caused to you. In most cases, a re-attempt would be initiated.However, if the issue is not getting resolved, please raise a support ticket' },

        },
        {
          name: 'How can I register a complaint against the courier executive who came to deliver my order? ',
          items:
            { name: 'We are sorry to know that you are unhappy with our delivery representative. Please raise a support ticket here. We assure you that your complaint will be resolved on priority.' },

        },

      ],
    },
    // =============================
    {
      name: 'Fragile shipments',
      sections: [
        {
          name: 'How does Company take care of fragile items??',
          items:
            { name: 'As the logistics partner, we request senders to provide such details beforehand based on which fragile items are segregated and handled with care.' },

        },

      ],
    },
    // =========================================
    {
      name: 'General',
      sections: [
        {
          name: 'How can I courier my personal parcel through company?',
          items:
            { name: 'You can send your personal parcels from here. Place a request along with all your details and well pick it up from your doorstep..' },

        },

      ],
    },
    // ========================================
    {
      name: 'Partnerships',
      sections: [
        {
          name: 'How do I partner with Company??',
          items:
            { name: 'You can partner with us as a transporter, fleet owner or a franchisee.' },

        },
        {
          name: 'I want to work with Company as a franchise partner. How can I do so? ',
          items:
            { name: 'Please visit our official website and fill the form to reach our team.' },

        },

      ],
    },
  ],
}
const AccordionHelp = () => {

  useScrollToTop();


  const [isOpen, setIsOpen] = useState({});
  const [handleinputsupport] = useMutation(CREATE_SUPPORT);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const toggleContent = (section) => {
    const newIsOpen = { ...isOpen };
    newIsOpen[section.name] = !isOpen[section.name] || false;
    setIsOpen(newIsOpen);
  };
  const formik = useFormik({
    initialValues: {
      name: '',
      awbNo: '',
      mobile: '',
      message: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(40, 'Must be 15 characters or less')
        .required('Enter First name'),
      awbNo: Yup.string()
        .max(20, 'Must be 20 characters or less')
        .required('Enter AWB No'),
      mobile: Yup.string()
        .required('Enter Phone number')
        .matches(/^[0-9]+$/, 'Must be only digits')
        .min(10, 'Must be exactly 10 digits')
        .max(10, 'Must be exactly 10 digits'),
      message: Yup.string()
        .max(200, 'Must be 200 characters or less')
        .required('Enter Message'),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const data = await handleinputsupport({
          variables: {
            input: {
              name: values?.name,
              mobile: values?.mobile,
              awbNo: values?.awbNo,
              message: values?.message,
            }
          }
        });
        if (data?.data?.createSupport?.status) {
          toast.success(data?.data?.createSupport?.message);
          resetForm();
          setIsSubmitDisabled(true);
        }
      } catch (err) {
        toast.error(err);
      }
    },
  });


  return (
    <div>
      <Header />

      <div className="bg-white">
        <div className="pt-6">
          <div className="mx-auto max-w-auto px-4 pt-8 sm:px-9 lg:grid lg:max-w-auto lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:py-3">
            <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
              <h1 className="text-xl font-semibold tracking-tight text-gray-900 sm:text-2xl mb-4 sm:mb-6">Frequently Asked Questions</h1>
            </div>
            <div className=" lg:row-span-10">
              <div className=" lg:grid lg:grid-cols-1">

                <div className="isolate bg-white">
                  <div className="mx-auto max-w-auto">
                    <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-xl">Raise a Query
                    </h2>
                    <p className="mt-2 text-sm text-gray-600">
                      Need support with your shipment?
                    </p>
                  </div>

                  <form onSubmit={formik.handleSubmit} className="mx-auto mt-10 max-w-auto md:mb-16 sm:my-8">
                    <div className="grid grid-cols-1 gap-x-5 gap-y-2 sm:grid-cols-2">
                      <div>
                        <label htmlFor="name" className="block text-sm font-semibold leading-6 text-gray-900">
                          First name <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-2.5">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Enter your first name"
                            className={`block focus:outline-none focus:ring-slate-600 focus:border-slate-600 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 ring-1 ring-inset ring-slate-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ${formik.touched.name && formik.errors.name ? 'border-red-500' : ''}`}
                            {...formik.getFieldProps('name')}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div className="text-red-500 text-sm">{formik.errors.name}</div>
                          ) : null}
                        </div>
                      </div>

                      <div>
                        <label htmlFor="awbNo" className="block text-sm font-semibold leading-6 text-gray-900">
                          AWB No <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-2.5">
                          <input
                            type="text"
                            name="awbNo"
                            id="awbNo"
                            placeholder="Enter AWB number"
                            className={`block focus:outline-none focus:ring-slate-600 focus:border-slate-600 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 ring-1 ring-inset ring-slate-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ${formik.touched.awbNo && formik.errors.awbNo ? 'border-red-500' : ''}`}
                            {...formik.getFieldProps('awbNo')}
                          />
                          {formik.touched.awbNo && formik.errors.awbNo ? (
                            <div className="text-red-500 text-sm">{formik.errors.awbNo}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <label htmlFor="mobile" className="block text-sm font-semibold leading-6 text-gray-900">
                          Phone number <span className="text-red-500">*</span>
                        </label>
                        <div className="relative mt-2.5">
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                              +91
                            </span>
                            <input
                              type="tel"
                              name="mobile"
                              id="mobile"
                              placeholder="Enter your phone number"
                              className={`block focus:outline-none rounded-r-md focus:ring-slate-600 focus:border-slate-600 w-full px-4 py-2 text-gray-900 ring-1 ring-inset ring-slate-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ${formik.touched.mobile && formik.errors.mobile ? 'border-red-500' : ''}`}
                              {...formik.getFieldProps('mobile')}
                            />
                          </div>
                          {formik.touched.mobile && formik.errors.mobile ? (
                            <div className="text-red-500 text-sm">{formik.errors.mobile}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
                          Message <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-2.5">
                          <textarea
                            name="message"
                            id="message"
                            rows={4}
                            placeholder="Enter your message"
                            className={`block focus:outline-none focus:ring-slate-600 focus:border-slate-600 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 ring-1 ring-inset ring-slate-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ${formik.touched.message && formik.errors.message ? 'border-red-500' : ''}`}
                            {...formik.getFieldProps('message')}
                          />
                          {formik.touched.message && formik.errors.message ? (
                            <div className="text-red-500 text-sm">{formik.errors.message}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="mt-10">
                      <button
                        type="submit"
                        className="block w-full rounded-md bg-slate-700 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-slate-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                      >
                        Let's talk
                      </button>
                    </div>
                    <div className='flex-col sm:flex-row space-y-3 my-10'>
                      <p className='font-bold md:mb-2 mb-4'>Our Strong Support : </p>
                      <p className='text-sm text-gray-500 '>
                        <a href="mailto:uk@uk-logistic.com" className='flex items-center'>
                          <span className='text- font-bold mr-2'>Raise a Query : </span>
                          uk@uk-logistic.com
                        </a>
                      </p>

                      <p className='text-sm text-gray-500 '>
                        <a href="tel:+17722444094" className='flex items-center'>
                          <IoEarth className="mr-2 text-xl text-slate-900" />
                          (+1) 772 244 4094 (USA)
                        </a>
                      </p>

                      <p className='text-sm text-gray-500 '>
                        <a href="tel:+17722444094" className='flex items-center'>
                          <IoEarth className="mr-2 text-xl text-slate-900" />
                          (+971) 12-345-6466 (Dubai)
                        </a>
                      </p>

                      <p className='text-sm text-gray-500 '>
                        <a href="tel:+9102269408494" className='flex items-center'>
                          <IoMdCall className="mr-2 text-xl text-slate-900" />
                          (+91) 022 6962 2461 (Mumbai)
                        </a>
                      </p>

                      {/* <p className='text-sm text-gray-500 '>
                        <a href="tel:+917096964646" className='flex items-center'>
                          <IoMdCall className="mr-2 text-xl text-slate-900" />
                          070 9696 4646 (Support)
                        </a>
                      </p>

                      <p className='text-sm text-gray-500 '>
                        <a href="tel:+917096964646" className='flex items-center'>
                          <IoMdCall className="mr-2 text-xl text-slate-900" />
                          026 2131 4803 (Enquiry)
                        </a>
                      </p> */}
                    </div>
                  </form>
                </div>

              </div>
            </div>
            {
              navigation.categories.map((category, index) => (
                <div key={index} className=" lg:col-span-2 lg:col-start-1 lg:border-r mt-11 lg:mt-2 lg:border-gray-200 text-black lg:pb-0 xl:pb-4  lg:pr-8 lg:pt-0" >
                  <h2 className="font-semibold tracking-tight text-black sm:text-md mb-4 ms-1">{category.name}</h2>
                  {category.sections.map((section, secIndex) => (
                    <div key={secIndex} className="border border-gray-300 bg-white text-black open:bg-white open:ring-1 open:ring-black/5 dark:open:ring-white/10 px-6 mb-3 py-2 rounded-lg" open>
                      <div onClick={() => toggleContent(section)} className="text-sm leading-6 bg-white text-slate-900 font-semibold select-none flex items-center">  {isOpen[section.name] ? <BiSolidDownArrow className='text-xs text-gray-700 me-1' /> : < BiSolidRightArrow className='text-xs  text-gray-700 me-1' />} {section.name}</div>

                      {isOpen[section.name] && (
                        <div className="mt-3 text-sm leading-6 text-slate-600 bg-white">
                          <p>{section.items.name}</p>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default AccordionHelp
