import React, { useState } from 'react';
import Header from '../Header/Header.js';
import Footer from '../Footer/Footer.js';
import TrackForm from './TrackForm.js';
import TrackingHistoryModal from './TrackingHistoryModal.js';
import table from '../asset/img/table.png';
import ware2 from '../asset/img/ware1.jpeg';
// import ware22 from '../asset/img/ware2.jpeg';
import ware22 from '../asset/img/ware3 (2).jpeg';
import useScrollToTop from '../components/useScrollToTop.js';


const TrackCourier = () => {
    useScrollToTop();

    const [trackingInfo, setTrackingInfo] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <div className=''>
            <Header />

            <div className="relative isolate overflow-hidden space-y-10 bg-gray-900 py-16 sm:py-14">
                <div className="mx-auto flex flex-col lg:flex-row gap-x-3 gap-y-10 max-w-auto md:px-8 lg:px-8">
                    <TrackForm setTrackingInfo={setTrackingInfo} setModalOpen={setModalOpen} />
                </div>

            </div>

            <div className='flex flex-col lg:flex-row items-center md:px-6 lg:px-8 px-6 mt-12 mb-7 space-y-5 md:space-y-0 lg:space-x-10'>
                <div className='flex w-full lg:w-1/3'>
                    <img src={ware2} className='rounded-xl hover:opacity-75 cursor-pointer w-full h-auto' alt="Ware" />
                </div>
                <div className='md:text-left w-full lg:w-2/3 md:pt-7 lg:pt-0'>
                    <p className='font-semibold text-lg mb-3'>Effortless Shipment Tracking with Instant</p>
                    <p>Our tracking system is user-friendly, designed to accommodate everyone, from those making infrequent purchases to businesses relying on regular shipments.</p>

                    <p className='font-semibold text-lg my-5'>Dedicated to a Smooth and Stress-Free Delivery Experience</p>
                    <p className='text-slate-700'>
                        ● At the heart of everything we do is our commitment to making your delivery experience as smooth and stress-free as possible. We know that timely and reliable shipping is essential, whether for personal reasons or business operations, and we strive to provide a system that meets your expectations.
                    </p>
                    <p className='text-slate-700 my-5'>
                        ● Our friendly team is not just here to support you when something goes wrong; they are available to answer general questions, provide advice on shipping options, or simply reassure you about your delivery’s progress.
                    </p>
                    <p className='text-slate-700'>
                        ● Simply input your Tracking AWB Number into our easy-to-navigate interface, and within seconds, you'll have full access to the most up-to-date status of your delivery. This feature is essential for individuals and businesses alike, giving you peace of mind as you monitor your shipment's progress, especially for time-sensitive or high-value deliveries.
                    </p>
                </div>
            </div>

            <div className='flex flex-col lg:flex-row items-center md:px-6 lg:px-8 px-6 mt-2 mb-7 space-y-5 md:space-y-0 lg:space-x-10'>
                <div className='flex w-full lg:w-1/3'>
                    <img src={ware22} className='rounded-xl hover:opacity-75 cursor-pointer w-full h-auto' alt="Ware" />
                </div>
                <div className='md:text-left w-full lg:w-2/3 md:pt-7 lg:pt-0'>
                    <p className='font-semibold text-lg mb-3'>Real-Time Shipment Tracking for Complete Transparency and Seamless Business Integration</p>
                    <p className='text-slate-700 mb-5'>● We believe in maintaining open communication throughout your shipment's journey. Whether your package is in transit, awaiting customs clearance, or out for delivery, you'll be kept in the loop. Our tracking system updates regularly to reflect any changes in the status of your delivery, providing complete transparency. This transparency is one of the cornerstones of our service, as it allows you to plan accordingly and avoid any surprises.</p>
                    <p className='text-slate-700 mb-5'>● For businesses, the ability to track shipments in real time is invaluable. It helps you manage your inventory, plan deliveries to customers, and even provide them with updates on their own shipments. Our system is designed to support business operations by offering easy integration with your logistics workflow. Whether you’re a small business owner sending out a few packages a week or a large corporation managing hundreds of shipments, our platform will scale to meet your needs, ensuring that you can track each and every shipment with precision.</p>
                    <p className='text-slate-700'>● One of the standout features of our service is its accessibility. You can access your shipment’s tracking information from any device, whether it’s a desktop computer, tablet, or smartphone. We understand that you're not always in front of a computer, and that’s why we've made sure our system works seamlessly on mobile devices. Whether you’re at home, in the office, or on the go, you’ll always have the ability to check on your delivery with just a few clicks.</p>
                </div>
            </div>

            <div className="mx-auto max-w-auto md:p-6 mb-7 space-y-6 lg:px-8">
                <div className='space-y-7'>
                    <p className='font-semibold text-lg'>1. Courier Picked
                    </p>
                    <p>This is the first step in the courier journey after the shipment is prepared. When the status shows "Courier Picked," it indicates that the delivery partner has collected the package from the sender or the dispatch location. The pickup could be from a business warehouse, a store, or a personal residence. This is the moment when the package officially enters the delivery process.</p>
                    <p className='font-semibold'>What Happens During This Stage:</p>
                    <p>Once the courier is picked up, it is scanned into the delivery company’s system. The sender typically receives a notification that the pickup has been completed, and the recipient is notified that the item is now en route. The package might go to a local sorting facility first, where it will be processed along with other shipments going in the same direction.

                    </p>
                    <p className='font-semibold'>What You Should Know as the Sender/Receiver:</p>
                    <p>For the sender, this stage marks the beginning of the shipment's journey. It’s a good idea to keep an eye on the tracking number at this point. For the recipient, this status provides the first confirmation that the item is on its way. Depending on the courier service, the delivery time estimate might be updated once the pickup is confirmed.

                    </p>
                </div>


                <div className='space-y-7'>
                    <p className='font-semibold text-lg'>2. Courier In Transit
                    </p>
                    <p>When a package is “In Transit,” it means it’s moving through the courier’s logistics system and is making its way toward the delivery address. This could involve multiple stages, including sorting, loading onto delivery vehicles, and transfers between different cities or states.</p>
                    <p className='font-semibold'>What Happens During This Stage:</p>
                    <p>Once the courier leaves the pickup location, it may first go to a local hub or a regional sorting center. From there, the package is sorted based on its destination. If it’s a long-distance shipment, it may travel by plane, train, or truck to another sorting facility closer to the recipient's location. Depending on the delivery network, a package may pass through multiple hubs before it reaches the final destination.

                        While "In Transit," your package might show several updates as it passes through different checkpoints, which may include arrival at sorting facilities, departure from hubs, and possible custom checks (in case of international deliveries).

                    </p>
                    <p className='font-semibold'>What You Should Know as the Sender/Receiver:</p>
                    <p>Both the sender and the recipient can track the shipment online during this stage. As the package moves, it’s important to note that delays can happen due to various factors like weather, logistics issues, or holidays. The “In Transit” status means the package is on its way, but not yet ready for delivery.

                    </p>
                </div>

                <div className='space-y-7'>
                    <p className='font-semibold text-lg'>3. Out For Delivery
                    </p>
                    <p>The "Out for Delivery" status signifies that the package has reached the final local hub and is now with the delivery executive. The courier is currently on its way to the recipient's address. This stage typically happens on the day of delivery.</p>
                    <p className='font-semibold'>What Happens During This Stage:</p>
                    <p>
                        Once a package reaches the local distribution hub near the delivery address, it is assigned to a courier executive. They load the package onto a delivery vehicle, usually with several other deliveries for the day, and set off for the recipient’s address. This is the final phase of the journey, and the package is just hours away from being delivered.
                        Some couriers may contact the recipient to confirm availability or provide an estimated time of arrival (ETA). The delivery vehicle may make multiple stops on the way to the recipient, so the exact delivery time can vary within the day.
                    </p>
                    <p className='font-semibold'>What You Should Know as the Sender/Receiver:</p>
                    <p>For the sender, this is the moment when the recipient is about to receive their package. It’s the last opportunity for any changes to be made to the delivery, such as changing the address or scheduling a re-delivery in case the recipient is unavailable. For the recipient, it’s important to make sure someone is present at the delivery location to sign for or collect the package. If no one is available, the courier may leave a note for re-delivery or drop off the package at a safe location (depending on the service).
                    </p>
                </div>

                <div className='space-y-7'>
                    <p className='font-semibold text-lg'>4. Delivered
                    </p>
                    <p>The final status, "Delivered," means that the package has successfully reached the recipient. The courier executive has handed it over to the recipient, someone at the address, or left it at a secure drop-off point as specified by the recipient or courier service.</p>
                    <p className='font-semibold'>What Happens During This Stage:</p>
                    <p>
                        Once the courier arrives at the delivery address, they will hand over the package to the person present. If there’s a signature required, the recipient or someone authorized will sign to confirm receipt. In some cases, the courier may take a photo as proof of delivery, especially for contactless deliveries.

                        If the recipient isn’t available, the courier may either attempt delivery again on the next business day or follow instructions like leaving the package with a neighbor, at a parcel locker, or at a designated drop-off location.
                    </p>
                    <p className='font-semibold'>What You Should Know as the Sender/Receiver:</p>
                    <p>As the sender, once the package is marked as “Delivered,” your responsibility is mostly complete, unless the recipient reports an issue (e.g., package damaged or missing). The recipient, on the other hand, should check the package for any signs of damage or tampering. If everything looks fine, the delivery is successful, and the transaction is complete.


                    </p>
                </div>

                <div className='space-y-7'>
                    <p>Tracking Your Package: Throughout these stages, the courier company provides a tracking number, allowing both sender and recipient to monitor the package's progress. Each status update reflects the current position or action taken on the shipment. This tracking information is essential, especially for businesses, to ensure that the shipment is proceeding smoothly.</p>
                    <p>Delays and Exceptions: Delays can happen during any of the stages mentioned above. "In Transit" can be prolonged due to bad weather, customs clearance issues, or logistical backlogs. Similarly, "Out for Delivery" may not always result in delivery on the same day if there are too many stops on the delivery route, or if no one is present to receive the package.
                    </p>
                    <p>Customer Communication: Many courier companies also send notifications via email, SMS, or app notifications to keep the recipient informed at each stage. The “Out for Delivery” and “Delivered” stages are particularly crucial, as this is when the recipient should ensure they are available to accept the package.
                    </p>
                    <p>Failed Delivery Attempts: If a courier is unable to deliver the package (for example, due to an incorrect address or no one being home), they will usually update the status to "Attempted Delivery" and provide further instructions for rescheduling or picking up the package from a local center.</p>
                </div>
            </div>

            {/* <div className="mx-auto max-w-auto md:p-6 mb-7 lg:px-8">
                <img src={table} alt="" />
            </div> */}
            <Footer />
            {modalOpen && trackingInfo && (
                <TrackingHistoryModal
                    trackingInfo={trackingInfo}
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                />
            )}
        </div>
    );
};

export default TrackCourier;
