import React from 'react'
import { Dialog, Transition } from '@headlessui/react';
import { BiLocationPlus } from "react-icons/bi";
import { SlLocationPin } from "react-icons/sl";
import { useMutation } from '@apollo/client';
import { DELETERECEIVER } from '../graphql/Mutation/Receiver.js';
import { toast } from 'react-toastify';
import { FaTrash } from 'react-icons/fa';
import useScrollToTop from '../components/useScrollToTop.js';

const AddressModal = ({ show, onClose, setShowModalAddress, setShowModal, setSelectedPickupAddress, data, refetch ,onSelectAddress}) => {

    useScrollToTop();
    // const id = localStorage.getItem('id');
    const [deletePickup] = useMutation(DELETERECEIVER);
    const handleOpenAddressDetails = () => {
        setShowModalAddress(true);
        setShowModal(false)
    };
    const handleSelectAddress = (address) => {
        setSelectedPickupAddress(address);
        onSelectAddress && onSelectAddress(address);
        onClose();
    };

    const capitalize = (text) => {
        if (!text) return text;
        return text.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
    };
    const handleDeleteAddress = async (id) => {
        deletePickup({ variables: { "deleteReceiverId": id } })
            .then(async ({ data }) => {
                refetch();
                toast.success("Receiver address deleted successfully!", { autoClose: 2000 });
                setSelectedPickupAddress('');

            })
            .catch((err) => {
                toast.error(err.message, { autoClose: 2000 });
            });
    }
    return (
        <>
            <Transition show={show}>
                <Dialog className="relative z-50" onClose={onClose}>
                    <Transition.Child
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-50 w-screen h-screen p-4 flex items-center justify-center overflow-y-auto">
                        <Transition.Child
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            {/* lg:max-w-xl xl:max-w-2xl 2xl:max-w-7xl */}
                           <Dialog.Panel className="relative w-full max-h-screen overflow-y-auto transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg lg:max-w-xl xl:max-w-2xl 2xl:max-w-7xl">
                             <div className="bg-white p-4 md:p-5">
                                    <div>
                                        <div className="mb-4">
                                            <h2 className="text-xl font-semibold">Select Pickup Address</h2>
                                        </div>
                                        <p className="border border-gray-100"></p>
                                        <button
                                            className="flex font-semibold items-center text-blue-600 my-4"
                                            onClick={handleOpenAddressDetails}
                                        >
                                            <BiLocationPlus className="mr-2 font-semibold text-xl" />
                                            Add New Address
                                        </button>
                                        <p className="border border-gray-100"></p>
                                        <h3 className="font-semibold text-sm text-gray-500 my-4">SAVED ADDRESSES</h3>
                                        <ul className="space-y-6 md:space-y-5">
                                            {data?.getReceiversOfCustomer?.data && data?.getReceiversOfCustomer?.data.length ? (
                                                data?.getReceiversOfCustomer?.data?.map((address, index) => (
                                                    <li key={index} className="flex justify-between items-center cursor-pointer">
                                                        <div className="flex items-center md:space-x-3 truncate">
                                                            <div className="text-gray-400 hidden md:block rounded-full border border-slate-300 text-lg p-2.5 font-light">
                                                                <SlLocationPin />
                                                            </div>
                                                            <div onClick={() => handleSelectAddress(address)}>
                                                                <div className="flex flex-col md:flex-row space-y-1 md:space-y-0 md:space-x-2">
                                                                    <p className="font-semibold text-gray-800 text-sm md:text-black">{capitalize(address.name)}</p>
                                                                    <p className="text-sm text-gray-500">| {capitalize(address.phone)}</p>
                                                                </div>

                                                                <div>
                                                                    <p className="text-slate-500 text-sm">{capitalize(address.address1)}.</p>
                                                                    <p className="text-slate-500 text-sm">{capitalize(address?.city)}, {capitalize(address?.stateId)} - {capitalize(address?.zipCode)}</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="flex ml-3 space-x-2">
                                                            <button
                                                                className="text-slate-800 hover:text-black text-sm font-semibold"
                                                                onClick={() => handleSelectAddress(address)}
                                                            >
                                                                Select
                                                            </button>
                                                            <button
                                                                onClick={() => { handleDeleteAddress(address?.id) }}
                                                            >
                                                                <FaTrash className="inline-block text-red-500 ml-1 mb-1" />
                                                            </button>
                                                        </div>
                                                    </li>
                                                ))
                                            ) : (
                                                <div className="flex justify-center items-center h-full my-8">
                                                    <p className="font-semibold text-gray-500">No Data Found</p>
                                                </div>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                                <div className="sticky bottom-0 bg-gray-50 px-4 py-3 sm:px-6">
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0"
                                        onClick={onClose}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};

export default AddressModal;
