import React from 'react'
import Header from '../Header/Header.js'
import Accordion from './Accodion.js';
import Footer from '../Footer/Footer.js';
import Mainone from './Mainone.js';
import Typefive from './Typefive.js';
import Typefour from './Typefour.js';
import Typesix from './Typesix.js';
import './Accodion.css'
import useScrollToTop from '../components/useScrollToTop.js';
import ware from '../asset/img/ware.jpeg';
import ware33 from '../asset/img/ware3 (1).jpeg';
// import ware22 from '../asset/img/ware3 (2).jpeg';
import ware22 from '../asset/img/ware2.jpeg';




const Home = () => {
    useScrollToTop();

    return (
        <div>
            <Header />

            <div className="relative isolate overflow-hidden bg-gray-900 pt-4 pb- mb-0.5 sm:pb- sm:pt-9">
                <video
                    // controls
                    autoPlay
                    loop
                    muted
                    className="absolute inset-0 brightlight -z-10 h-full w-full object-cover object-right md:object-center"
                >
                    <source src="https://vaghasiyakishan111.s3.ap-south-1.amazonaws.com/longVideo/Untitled_1080p_4.mp4" type="video/mp4" className='cursor-pointer' />
                    Your browser does not support the video tag.
                </video>

                <div className="mx-auto max-w-auto lg:px-8 px-6 text-white cursor-pointers">
                    <p className='font-bold text-3xl text-gray-100'>UK Logistics</p>
                    <p className='font-semibold text-xl mt-3 text-gray-200'>Domestic and International Shipping Services</p>
                    {/* <p className='text-lg mt-12 text-gray-300'>We offer door-to-door, port-to-port express and freight shipping services working directly with global airlines and ocean liners.</p>
                <p className='text-lg my-14 text-gray-300'>Our capabilities include an integrated network of express and freight solutions coupled with supplementary solutions of tech-enabled tracking, an in-house regulatory team for proactive clearance support, and a dedicated customer servicing team.</p>
                <p className='text-lg mt-7 text-gray-300'>We are FedEx’s strategic partners in India for global express shipping services.</p> */}
                    <p className='text-end mt-56 mb-3'>www.uk-logistic.com</p>
                </div>
            </div>
            <Mainone />
            <Typesix />

            <div className='flex flex-col lg:flex-row items-center md:px-6 lg:px-8 px-6 mt-2 mb-7 space-y-5 md:space-y-0 lg:space-x-10'>
                {/* Image Section */}
                <div className='flex w-full lg:w-1/3'>
                    <img src={ware} className='rounded-xl hover:opacity-75 cursor-pointer' alt="Ware" />
                </div>

                {/* Content Section */}
                <div className='md:text-left w-full lg:w-2/3 md:pt-7 lg:pt-0'>
                    <p className='font-semibold text-lg mb-3'>The Future of Logistics: Growing and Innovating</p>
                    <p className='text-slate-700'>
                        ● As we continue to grow, we remain committed to staying at the forefront of logistics innovation. Our goal is to not only meet but exceed the expectations of our customers by embracing new technologies, expanding our delivery network, and improving our services. We are constantly exploring ways to enhance the speed, security, and efficiency of our operations while maintaining our focus on providing exceptional customer support.
                    </p>
                    <p className='text-slate-700 my-5'>
                        ● In conclusion, our UK logistics company is built on a foundation of growth, innovation, and customer-first service. We prioritize the needs of our clients, ensuring that every parcel is delivered quickly, securely, and with the full support of our dedicated team. By leveraging digital technology, optimizing our processes, and maintaining a strong commitment to customer satisfaction, we provide a logistics experience that is second to none.
                    </p>
                    <p className='text-slate-700'>
                        ● Our commitment to customer satisfaction doesn’t end with delivery. We provide full support throughout the entire shipping process, from the moment you book a shipment to the point of delivery and beyond. Our customer service team is available to assist with any inquiries, provide updates, and resolve any issues that may arise. Whether you're facing a delayed shipment, need to modify delivery instructions, or have questions about our services, we're here to help.
                    </p>


                </div>
            </div>




            <div className='flex flex-col lg:flex-row items-center md:px-6 lg:px-8 px-6 mt-2 mb-7 space-y-5 md:space-y-0 lg:space-x-10'>
                {/* Image Section */}
                <div className='flex w-full lg:w-1/3'>
                    <img src={ware22} className='rounded-xl hover:opacity-75 cursor-pointer' alt="Ware" />
                </div>

                {/* Content Section */}
                <div className='md:text-left w-full lg:w-2/3 md:pt-7 lg:pt-0'>
                    <p className='font-semibold text-lg mb-7'>Embracing Digital Technology for Seamless Logistics</p>
                    <p>As part of our commitment to staying at the forefront of the logistics industry, we have embraced cutting-edge digital technologies that enable us to offer superior service and efficiency. The logistics sector is increasingly reliant on technology, and we have integrated digital tools across all facets of our operations to enhance the customer experience and streamline our internal processes.

                        One of our key innovations is the use of real-time tracking systems. From the moment your parcel is dispatched, you can follow its journey with our intuitive tracking interface, which provides up-to-the-minute updates on its location and status. This transparency allows you to plan accordingly and gives you peace of mind, knowing exactly when to expect your delivery.</p>
                    <p className='text-slate-700 mt-8'>
                        ● We also leverage digital technologies to optimize our internal workflows. Our warehouse management systems use AI and machine learning to predict demand, manage inventory levels, and ensure the efficient allocation of resources. This not only reduces operational costs but also allows us to process and dispatch orders with unprecedented speed and accuracy.
                    </p>
                    <p className='text-slate-700 my-5'>
                        ● We also prioritize data security, ensuring that all customer information and shipment details are protected. Our systems are built with robust encryption and cybersecurity measures to safeguard your data from unauthorized access or breaches. You can trust that your parcel and personal information are safe in our hands.
                    </p>
                    {/* <p className='text-slate-700'>
                        ● AI-predictive demand for efficient distribution network. The company leverages artificial intelligence to predict demand patterns and anticipate shipping requirements. Our AI technology guides us toward the optimal locations for new distribution centers to fulfill our partner's logistics needs.
                    </p>  */}
                </div>
            </div>


            <Typefive />




            <Typefour />
            <Accordion />
            <div className='mt-14'>
                <Footer />
            </div>
        </div>
    )
}

export default Home
